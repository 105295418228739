.user-badge {
  border-radius: 12px;
  border: 1px solid transparent;
  transition: all 0.25s ease;
  cursor: inherit;
}
.user-badge.menu-enabled:hover {
  cursor: pointer;
  border: 1px solid var(--outline-color);
  background-color: var(--translucent);
}
.user-badge.menu-enabled.active {
  border: 1px solid var(--outline-color);
  background-color: var(--translucent);
}
.user-badge.menu-enabled.active .caret {
  transform: rotate(180deg);
}
.user-badge.internal {
  background-color: var(--primary-blue-semi);
  border: 1px solid var(--primary-blue-dim);
}
.user-badge.internal:hover {
  background-color: var(--primary-blue-dim);
  border: 1px solid var(--primary-blue);
}

.user-badge > div {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
  align-items: center;
  margin: 6px 8px;
}
.user-badge .user-info {
  display: grid;
  grid-template-rows: auto auto;
}

.user-info .user-info-primary {
  font-size: 14px;
  font-weight: 500;
}
.user-info .user-info-secondary {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
}

.user-badge-menu {
  margin-top: 8px;
}
.user-badge-menu .menu-item {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  gap: 32px;
  align-items: center;
  color: var(--light-font-color);
  width: 100%;
}

.user-badge-menu .menu-item svg {
  vertical-align: middle;
}
