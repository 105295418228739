.vehicles {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-rows: auto 1fr;
  gap: 8px;
}

.vehicles .page-toolbar {
  margin: 8px 24px;
}

.vehicles .vehicles-header {
  display: grid;
}

.vehicles .vehicles-container {
  display: grid;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.vehicles .list-container {
  margin: 0 24px 24px 24px;
  overflow-x: hidden;
  min-width: 1200px;
}

.vehicle-modal .status-toggle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  justify-items: center;
}

.vehicle-modal .status-toggle > div {
  width: 100%;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.vehicle-modal .status-toggle-content {
  gap: 8px;
  display: inline-grid;
  grid-template-columns: auto auto;
  color: var(--base-font-color);
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0;
}

.vehicle-modal .status-toggle .status-online,
.vehicle-modal .status-toggle .status-offline {
  border: 1px solid var(--outline-color);
}

.vehicle-modal .status-toggle .status-online:hover,
.vehicle-modal .status-toggle .status-online.selected {
  border: 1px solid transparent;
  background-color: var(--primary-green);
}

.vehicle-modal .status-toggle .status-offline:hover,
.vehicle-modal .status-toggle .status-offline.selected {
  border: 1px solid transparent;
  background-color: var(--primary-red);
}

.vehicle-modal .not-available {
  font-style: italic;
  color: var(--primary-yellow);
  font-size: 14px;
  opacity: 0.5;
}

.vehicle-modal .modal-field-row.toggle-input {
  display: grid;
}
.vehicle-modal .modal-field-row.toggle-input > div {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin: 16px 0;
}
.vehicle-modal .modal-field-row .button-switch {
  width: auto;
}

.vehicle-modal .modal-field-row .button-switch-box {
  grid-template-columns: 1fr 1fr;
}
.vehicle-modal .modal-field-row.toggle-input .button-switch .selected {
  background-color: var(--light-bg-color);
}
.vehicle-modal .modal-field-row.toggle-input.offline .button-switch .selected {
  background-color: var(--primary-red);
}

.vehicle-modal
  .modal-field-row.toggle-input
  .button-switch
  .button-switch-option
  span {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

/** GSE filter button/menu **/
.gse-filter {
  display: grid;
  position: relative;
}
.gse-filter .gse-filter-menu {
  display: none;
  position: absolute;
  width: 280px;
  background-color: var(--darker-bg-color);
  border: 1px solid var(--outline-color);
  border-radius: 12px;
  top: 48px;
  right: 0;
  z-index: 3;
}
.gse-filter.open .gse-filter-menu {
  display: grid;
  max-height: 320px;
  overflow-y: auto;
}
.gse-filter .gse-filter-menu-content {
  display: grid;
  margin: 10px 12px;
  gap: 4px;
}
.gse-filter .gse-filter-menu-title {
  display: grid;
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
  text-transform: uppercase;
  cursor: default;
}
.gse-filter .gse-filter-menu-title > div {
  display: grid;
  margin: 8px;
  cursor: default;
}
.gse-filter .gse-filter-menu-row {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-font-color);
  transition: all 0.25s ease-out;
  cursor: pointer;
  border-radius: 8px;
}
.gse-filter .gse-filter-menu-row.selected {
  color: var(--base-font-color);
}
.gse-filter .gse-filter-menu-row:hover {
  background-color: var(--semi-transparent);
  color: var(--base-font-color);
}

.gse-filter .gse-filter-menu-row > div {
  display: grid;
  margin: 8px 12px;
}
.gse-filter .gse-filter-menu-row .checkbox-container {
  display: grid;
}
