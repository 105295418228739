.notifications {
  height: 100%;
  overflow: hidden;
}

.notifications .notifications-container {
  display: grid;
  height: 100%;
  margin: 0 16px 0 32px;
  overflow-y: hidden;
  gap: 8px;
  grid-template-rows: auto 1fr;
}

.notifications .status-new {
  background-color: #1779da;
  padding: 4px 8px;
  border-radius: 8px;
  display: inline-grid;
  font-size: 14px;
  font-weight: 600;
  visibility: hidden;
}

.notifications.show-new .status-new {
  visibility: visible;
}

.notifications-empty-state {
  height: 320px;
  display: grid;
  align-items: center;
}

.notifications-empty-state > div {
  display: grid;
  margin: 32px;
  gap: 8px;
}
.notifications-empty-state .notifications {
  margin: 24px 0;
}
