/** Flight progress **/
.flight-progress {
  display: grid;
  gap: 8px;
  margin: 4px;
}

.flight-progress .flight-progress-info {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  border-radius: 6px;
  overflow: hidden;
}
.flight-progress .flight-progress-info span {
  display: grid;
  margin: 4px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: var(--light-font-color);
}

.flight-progress .flight-airport-badge {
  display: grid;
  color: var(--light-font-color);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
}
.flight-progress .flight-progress-status {
  color: var(--light-font-color);
  text-align: center;
  font-size: 10px;
  font-weight: 500;
}
.flight-progress .flight-progress-status-bar-container {
  display: grid;
  margin: 4px 0;
}
.flight-progress .flight-progress-status-bar {
  background-color: var(--placeholder);
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: relative;
}
.flight-progress .flight-progress-status-bar-fill {
  background-color: var(--primary-off-white);
  height: 6px;
  border-radius: 4px;
}

.flight-progress .plane-icon {
  display: block;
  position: absolute;
}
.flight-progress .plane-icon svg {
  width: 18px;
  height: 18px;
}
