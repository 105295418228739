.list-container {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: auto;
  scrollbar-color: var(--scrollbars);
  background-color: var(--darker-bg-color);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  opacity: 1;
  transition: opacity 1s ease;
  min-width: 1200px;
}
.list-row,
.list-header {
  display: grid;
  grid-template-columns: repeat(2, 5fr) 1fr;
}

.list-row {
  border-top: 1px solid var(--dark-bg-color);
  border-bottom: 1px solid var(--outline-darker-color);
}
.list-row:hover,
.list-row.selected {
  background-color: var(--intermediate-bg-color);
  transition: background-color 0.25s ease;
}
.list-row.clickable {
  cursor: pointer;
}

.list-row button {
  padding: 8px;
  text-wrap: nowrap;
  font-size: 12px;
}
.list-header {
  position: sticky;
  top: 0;
  background-color: var(--dark-bg-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.list-header .list-header-col:first-child {
  z-index: 3;
}
.list-header .list-multiselect,
.list-row .list-multiselect {
  display: grid;
  align-items: center;
  text-align: center;
}

.list-row .list-actions {
  display: grid;
  grid-template-columns: auto auto;
}

.list-row .list-actions .button-icon {
  margin: 8px;
  cursor: pointer;
}
.list-row .list-actions .button-icon:hover path {
  fill: var(--base-font-color);
}

.list-row > div,
.list-header > div {
  margin: 16px 8px;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.list-row > div:first-child,
.list-row > div:last-child,
.list-header > div:first-child,
.list-header > div:last-child {
  margin: 16px;
}

.list-actions {
  text-align: center;
}

.list-header-name {
  display: grid;
  grid-template-columns: auto 1fr;
  cursor: pointer;
  align-items: center;
}
.list-header .sort-arrow {
  display: inline;
  visibility: hidden;
  vertical-align: middle;
  margin-left: 4px;
}
.list-header-name:hover .sort-arrow {
  visibility: visible;
}
.list-header-col.sorted {
  color: var(--primary-blue);
}
.list-header-col svg {
  vertical-align: middle;
}
.list-header-col.sorted svg path {
  fill: var(--primary-blue);
}
.list-header-col.sorted .sort-arrow {
  visibility: visible;
}
.list-header-col.sorted .sort-arrow svg {
  transform: rotate(180deg);
}
.list-header-col.sorted .sort-arrow.desc svg {
  transform: rotate(0deg);
}

.list-row > div {
  display: grid;
  vertical-align: middle;
  align-items: center;
}

.list-row .warning {
  color: var(--primary-yellow);
  font-style: italic;
  opacity: 0.5;
}

.list-row .danger {
  color: var(--primary-red);
}

.list-row .numbered {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}
.list-row .numbered-value {
  font-weight: 600;
  font-size: 14px;
}

.datatable-turnaround-info {
  display: grid;
}

.datatable-turnaround-info > div {
  font-weight: 500;
  font-size: 14px;
  color: var(--base-font-color);
  text-transform: uppercase;
}

.datatable-turnaround-info .delayed {
  color: var(--primary-orange);
}

.datatable-turnaround-info span {
  font-weight: 500;
  font-size: 10px;
  color: var(--medium-font-color);
}

.datatable-turnaround-status {
  display: grid;
  gap: 4px;
}
.datatable-turnaround-status > div {
  font-weight: 500;
  font-size: 14px;
  color: var(--base-font-color);
  text-transform: uppercase;
}

.datatable-turnaround-status .datatable-turnaround-critical-issues {
  font-weight: 500;
  font-size: 10px;
  color: var(--primary-red);
  text-transform: uppercase;
}

.list-column-value .turnaround-flights {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
}

.list-column-value .turnaround-flights .flight-status-icon {
  border: none;
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
.list-column-value .turnaround-flights .flight-status-icon.status-1 {
  background-color: transparent;
  width: 6px;
  height: 6px;
  border: 1px dotted var(--pure-white-dim);
  margin: 5px;
}
.list-column-value .turnaround-flights .flight-status-icon.status-2 {
  background-color: var(--pure-white-dim);
  width: 8px;
  height: 8px;
  margin: 5px;
}
.list-column-value .turnaround-flights .flight-status-icon.status-3 {
  background-color: transparent;
  width: 18px;
  height: 18px;
  margin: 0;
}
/* complete */
.list-column-value .turnaround-flights .flight-status-icon.status-4 {
  background-color: transparent;
  width: 6px;
  height: 6px;
  border: 1px dotted var(--pure-white-dim);
  margin: 5px;
}
.list-column-value .turnaround-flights .flight-status svg {
  border: none;
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

.list-column-value .flight-names,
.list-column-value .origin-destination {
  display: flow;
}
.list-column-value .flight-names > div,
.list-column-value .origin-destination > div {
  display: inline-flex;
  white-space: nowrap;
}
.list-column-value .flight-critical-indicator svg {
  margin-top: 2px;
  width: 18px;
  height: 18px;
}
.list-row.status-invalid .flight-names {
  color: var(--primary-red);
}
.list-row.status-invalid .invalid-time {
  color: var(--primary-red);
}

.list-column-value .remarks {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
}

.list-column-value .turnaround-stand {
  display: grid;
  gap: 2px;
}
.list-column-value .highlight-positive {
  color: var(--primary-orange);
}
.list-column-value .highlight-negative {
  color: var(--primary-orange);
}
.list-column-value .highlight-value {
  color: var(--primary-orange);
}
.list-column-value .previous-value {
  font-size: 12px;
  font-weight: 500;
  color: var(--medium-font-color);
  text-decoration: line-through;
}

.list-column-value .date-value {
  font-size: 10px;
  font-weight: 500;
  color: var(--light-font-color);
}
.list-column-value .value-type {
  font-size: 10px;
  font-weight: 500;
  text-transform: lowercase;
  margin-left: 4px;
}

.list-column-value .flight-summary-time-value {
  display: grid;
  grid-template-rows: 1fr auto;
  font-size: 14px;
  font-weight: 500;
}

.list-column-value .flight-summary-time-value span {
  font-size: 10px;
  font-weight: 500;
  color: var(--light-font-color);
}

/** Chat button **/
.chat-button {
  display: grid;
  width: 32px;
  height: 32px;
  opacity: 0.5;
  transition: all 0.25s ease-out;
  background-color: transparent;
  border-radius: 50%;
}
.chat-button:hover {
  opacity: 1;
  background-color: var(--primary-blue-semi);
}
.chat-button.active {
  opacity: 1;
}
.chat-button > div {
  display: grid;
  position: relative;
  margin: 4px;
}
.chat-button svg {
  width: 24px;
  height: 24px;
}
.chat-button .chat-button-indicator {
  position: absolute;
  width: 12px;
  height: 12px;
  font-size: 8px;
  color: var(--base-font-color);
  background-color: var(--primary-blue);
  border-radius: 50%;
  /* border: 1px solid var(--outline-light-color); */
  align-content: center;
  justify-items: center;
  top: -2px;
  right: -4px;
  transition: all 0.25s ease-out;
}
.chat-button:hover .chat-button-indicator {
  width: 14px;
  height: 14px;
  top: -3px;
  right: -5px;
  font-size: 9px;
}
