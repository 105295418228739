.metrics-filter {
  position: relative;
  opacity: 0.7;
}
.metrics-filter:hover {
  opacity: 1;
}

.metrics-filter .metrics-filter-toggle {
  border: 1px solid var(--outline-medium-color);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.25s ease;
}
.metrics-filter .metrics-filter-toggle:hover {
  background-color: var(--darker-bg-color);
}
.metrics-filter.active .metrics-filter-toggle {
  background-color: var(--medium-bg-color);
  border: 1px solid var(--outline-light-color);
}
.metrics-filter.active .metrics-filter-toggle svg {
  transform: rotate(180deg);
}
.metrics-filter.enabled .metrics-filter-toggle svg path,
.metrics-filter.active .metrics-filter-toggle svg path {
  fill: var(--base-font-color);
}
.metrics-filter.enabled .metrics-filter-toggle {
  background-color: var(--primary-blue);
  border: 1px solid var(--primary-blue);
}

.metrics-filter .inner-label {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  text-wrap: nowrap;
}
.metrics-filter .inner-label.icon {
  grid-template-columns: auto 1fr;
  margin: 9px 12px 9px 10px;
}
.metrics-filter .inner-label.icon svg {
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.metrics-filter .metrics-filter-menu {
  position: absolute;
  background-color: var(--darkest-bg-color);
  border: 1px solid var(--outline-medium-color);
  border-radius: 8px;
  top: 42px;
  z-index: 3;
  font-size: 14px;
  font-weight: 600;
}
.metrics-filter.left .metrics-filter-menu {
  left: 0;
}
.metrics-filter.right .metrics-filter-menu {
  right: 0;
}
.metrics-filter .metrics-filter-container {
  max-height: 320px;
  overflow: auto;
}

.metrics-filter .metrics-filter-content {
  display: grid;
  gap: 8px;
  margin: 16px;
}
.metrics-filter .metrics-filter-option {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  text-wrap: nowrap;
}
.metrics-filter .metrics-filter-option label {
  font-size: 14px;
  font-weight: 500;
  color: var(--light-font-color);
}

.metrics-filter .metrics-filter-option:hover label {
  color: var(--base-font-color);
  cursor: pointer;
}

.metrics-filter .metrics-filter-option.selected label {
  color: var(--primary-blue);
}
