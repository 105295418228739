:root {
  --base-font-color: #ffffff;
  --light-font-color: #a3a9b6;
  --medium-font-color: #a5a5ab;
  --dark-font-color: #6c6c6c;
  --darker-font-color: #161c27;
  --input-font-color: #c2c6ce;
  --placeholder-font-color: #4a4c56;
  --base-bg-color: #0f1016;
  --medium-bg-color: #242731;
  --dark-bg-color: #161c27;
  --intermediate-bg-color: #232a37;
  --darker-bg-color: #161721;
  --darkest-bg-color: #07080b;
  --light-bg-color: #2f3746;
  --lighter-bg-color: #a3a9b6;
  --highlight-dark-bg-color: #0d1118;
  --highlight-bg-color: #ffffff;
  --outline-color: #4a4c56;
  --outline-light-color: #a3a9b6;
  --outline-semi-color: rgba(163, 169, 182, 0.5);
  --outline-medium-color: #424959;
  --outline-dark-color: #2f3746;
  --outline-darker-color: #232936;
  --primary-blue: #1779da;
  --primary-blue-dim: rgba(23, 121, 218, 0.5);
  --primary-blue-semi: rgba(23, 121, 218, 0.3);
  --alternate-blue: #1a273d;
  --primary-orange: #dc6a28;
  --primary-orange-dim: rgba(220, 106, 40, 0.5);
  --primary-orange-semi: rgba(220, 106, 40, 0.3);
  --primary-yellow: #ffd964;
  --primary-yellow-dim: rgb(255, 217, 100, 0.5);
  --primary-yellow-semi: rgb(255, 217, 100, 0.3);
  --primary-yellow-tint: rgb(255, 217, 100, 0.05);
  --primary-green: #1fa368;
  --primary-green-semi: rgba(31, 163, 104, 0.3);
  --primary-green-tint: rgba(31, 163, 104, 0.25);
  --primary-red: #d02f44;
  --primary-red-dim: rgba(208, 47, 68, 0.5);
  --primary-red-semi: rgba(208, 47, 68, 0.3);
  --primary-red-tint: rgba(208, 47, 68, 0.05);
  --primary-off-white: #fafafa;
  --pure-black: #000000;
  --pure-white: #ffffff;
  --gray-medium-color: #232428;
  --gray-dimmed-color: #2f3847;
  --gray-light-color: #4c586c;
  --pure-white-dim: rgba(255, 255, 255, 0.5);
  --pure-white-semi: rgba(255, 255, 255, 0.3);
  --pure-white-tint: rgba(255, 255, 255, 0.15);
  --translucent: rgba(46, 48, 54, 0.9);
  --semi-transparent: rgba(255, 255, 255, 0.03);
  --placeholder: #4a4c56;
  --light-placeholder-font: #8a94a6;
  --input-bg-color: #1d1f2c;
  --divider-color: #1d1f2c;
  --tile-color: #1d1f2c;
  --scrollbars: rgba(255, 255, 255, 0.25) rgba(0, 0, 0, 0);
  --scrollbars-transparent: rgba(255, 255, 255, 0) rgba(0, 0, 0, 0);
  --disabled-bg-color: rgba(163, 169, 182, 0.15);
  --drop-shadow-strong-color: rgba(0, 0, 0, 0.75);
  --drop-shadow-color: rgba(0, 0, 0, 0.5);
  --drop-shadow-light-color: rgba(0, 0, 0, 0.15);
  --overlay-color: rgba(0, 0, 0, 0.25);
  --overlay-gray-color: rgba(36, 39, 49, 0.5);
  --metrics-1: #4a5654;
  --metrics-2: #2bb2fe;
  --metrics-3: #1779da;
  --metrics-4: #c38306;
  --chart-series-0: #1779da;
  --chart-series-1: #626262;
  --chart-series-2: #b0b0b0;
  --chart-series-3: #c20962;
  --chart-series-4: #f76daf;
  --chart-series-5: #9d09c2;
  --chart-series-6: #dc6df7;
  --chart-series-7: #5b10bb;
  --chart-series-8: #aa72f2;
  --chart-series-9: #0b28c2;
  --chart-series-10: #6e84f7;
  --chart-series-11: #0a7fc1;
  --chart-series-12: #6dc5f7;
  --chart-series-13: #07b0a6;
  --chart-series-14: #6beae2;
  --chart-series-15: #0ab372;
  --chart-series-16: #9ef3d2;
  --chart-series-17: #438e09;
  --chart-series-18: #99d06d;
  --chart-series-19: #c38306;
  --chart-series-20: #f9c86b;
  --chart-series-21: #bf4c0c;
  --chart-series-22: #f59f6f;
  --chart-series-23: #c20909;
  --chart-series-24: #f76d6d;
}
