.deployments {
  display: grid;
  background-color: var(--dark-bg-color);
  border-radius: 12px;
  grid-template-columns: 1fr 1fr;
}

.deployments > div {
  margin: 24px;
}
.deployments .deployments-right-panel > div {
  display: grid;
  border: 1px solid var(--outline-color);
  border-radius: 12px;
}
.deployments .deployments-right-panel .form-container {
  display: grid;
  margin: 16px;
}
.deployments .deployments-container {
  display: grid;
  height: 100%;
  margin: 0 16px;
  overflow-y: hidden;
  gap: 8px;
  grid-template-rows: auto 1fr;
}

.deployments .form-container {
  display: grid;
  max-width: 480px;
  grid-template-rows: auto auto auto 1fr;
  gap: 16px;
}

.deployments .form-container .field-row {
  display: grid;
  gap: 8px;
}

.deployments .resource-actions-rows {
  display: grid;
}
.deployments .resource-actions {
  border-radius: 8px;
}
.deployments .resource-actions > div {
  display: grid;
  grid-template-columns: 48px 1fr 1fr;
  font-size: 12px;
  font-weight: 500;
  gap: 16px;
  color: var(--light-font-color);
  margin: 4px 12px;
  transition: all 0.25s ease-out;
}
.deployments .resource-actions:hover {
  background-color: var(--light-bg-color);
}
.deployments .resource-actions .resource-actions-value {
  text-transform: uppercase;
}
.deployments .resource-actions.enabled > div {
  color: var(--base-font-color);
}
.deployments .resource-actions.enabled .resource-actions-value {
  color: var(--primary-green);
}
