.sub-tabs {
  display: grid;
  border-bottom: 1px solid var(--outline-color);
  margin: 0 16px;
}
.sub-tabs > div {
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  gap: 16px;
}

.sub-tabs .sub-tabs-label,
.sub-tabs .sub-tabs-item {
  border-bottom: 2px solid transparent;
  color: var(--medium-font-color);
}
.sub-tabs .sub-tabs-label {
  cursor: default;
}
.sub-tabs .sub-tabs-item {
  transition: all 0.25s ease-out;
  cursor: pointer;
}

.sub-tabs .sub-tabs-item:hover,
.sub-tabs .sub-tabs-item.selected {
  border-bottom: 2px solid var(--base-font-color);
  color: var(--base-font-color);
}

.sub-tabs .sub-tabs-label > div,
.sub-tabs .sub-tabs-item > div {
  margin: 8px 4px 16px 4px;
}
