.toggle-switch {
  display: grid;
  width: 50px;
  background-color: var(--darkest-bg-color);
  border-radius: 14px;
  transition-property: background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  cursor: pointer;
}
.toggle-switch.selected {
  background-color: var(--primary-blue);
  transition-property: background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}

.toggle-switch.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.toggle-switch > div {
  margin: 5px;
  display: grid;
  justify-content: left;
}

.toggle-switch.selected > div {
  justify-content: right;
}

.toggle-switch .knob {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: var(--light-font-color);
}
.toggle-switch.selected .knob {
  background-color: var(--pure-white);
}
