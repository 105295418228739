.chat {
  display: grid;
  height: 100%;
  overflow: hidden;
}

.chat .chat-module {
  display: grid;
  grid-template-columns: 380px 1fr;
  margin: 32px;
  background-color: var(--darker-bg-color);
  border-radius: 12px;
  overflow: hidden;
}

.chat .chat-side-panel {
  border-right: 1px solid var(--outline-medium-color);
  overflow-y: auto;
}
.chat .chat-side-panel button {
  padding: 8px 12px;
}

.chat .chat-side-panel-item {
  display: grid;
  grid-template-columns: 1fr auto;
  cursor: default;
  align-items: center;
}
.chat .chat-side-panel-item.channel .chat-channel {
  cursor: pointer;
}
.chat .chat-side-panel-item.channel .chat-channel .crew-info {
  cursor: inherit;
}
.chat .chat-side-panel-item.channel .chat-channel .crew-info-detail {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat .chat-side-panel-item.channel .chat-channel:hover,
.chat .chat-side-panel-item.channel.selected .chat-channel {
  background-color: var(--light-bg-color);
  transition: all 0.25s ease-out;
}
.chat .chat-side-panel-item.channel .chat-channel:hover {
  background-color: var(--intermediate-bg-color);
}

.chat .chat-side-panel-item.channel.selected .chat-channel {
  border: 1px solid var(--outline-light-color);
}

.chat .chat-side-panel-item > div {
  margin: 0 16px;
}
.chat .chat-side-panel-content {
  display: grid;
  grid-template-rows: 80px 1fr;
  height: 100%;
  align-content: center;
}

.chat .chat-side-panel-channels {
  display: grid;
  overflow-y: auto;
  align-content: start;
  gap: 16px;
}

.chat .chat-channel-empty {
  display: grid;
  cursor: default;
}
.chat .chat-channel-empty > div {
  display: grid;
  margin: 16px 24px;
  gap: 4px;
  border-bottom: 1px solid var(--outline-medium-color);
}
.chat .chat-channel-empty-label {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--base-font-color);
}
.chat .chat-channel-empty-text {
  font-size: 12px;
  color: var(--light-font-color);
  margin-bottom: 24px;
}

.chat .chat-main-panel {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.chat .chat-main-panel .chat-header > div {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin: 16px;
  gap: 16px;
}
.chat .chat-main-panel .chat-body {
  border-top: 1px solid var(--outline-medium-color);
  border-bottom: 1px solid var(--outline-medium-color);
  overflow-x: hidden;
  overflow-y: auto;
}
.chat.read-only .chat-main-panel .chat-body {
  border-bottom: none;
}

.chat .chat-main-panel .chat-unread-overlay {
  display: grid;
  position: absolute;
  z-index: 2;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  height: 64px;
  width: 100%;
  bottom: 68px;
  text-align: center;
}
.chat .chat-main-panel .chat-unread-overlay > div {
  margin: 12px;
}
.chat .chat-main-panel .chat-footer > div {
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 16px;
  gap: 16px;
  align-items: center;
}
.chat .chat-main-panel .chat-footer button {
  padding: 8px 10px;
}

.chat .chat-channel {
  display: grid;
  border: 1px solid var(--outline-medium-color);
  border-radius: 12px;
}
.chat .chat-channel > div {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;
  margin: 12px;
}
.chat .chat-channel .chat-channel-name {
  display: grid;
  font-size: 14px;
  font-weight: 600;
  color: var(--medium-font-color);
}

.chat .chat-channel.selected .chat-channel-name {
  color: var(--base-font-color);
}

.chat .unread-indicator {
  display: grid;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: transparent;
  margin: 12px;
}
.chat .unread-indicator.active {
  background-color: var(--primary-blue);
}

.chat .chat-category {
  margin: 16px;
  font-weight: 600;
}
/** Channel Info **/
.chat .channel-info {
  display: grid;
}

.chat .channel-info .channel-info-name {
  display: grid;
  align-items: center;
}
.chat .channel-info .channel-info-name.details {
  grid-template-rows: auto auto;
}

.chat .channel-info .channel-info-name div {
  font-size: 14px;
  font-weight: 500;
  color: var(--base-font-color);
}
.chat .channel-info .channel-info-name span {
  font-size: 12px;
  font-weight: 500;
  color: var(--medium-font-color);
}
.chat .chat-header {
  display: grid;
  height: 80px;
  align-items: center;
}
.chat .channel-info .crew-info-name {
  color: var(--base-font-color);
}
.chat .channel-info .crew-info-detail {
  color: var(--light-font-color);
  font-weight: 500;
}

.chat .channel-info .channel-info-badge {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 600;
  color: var(--base-font-color);
}
.chat .channel-info .channel-info-badge svg {
  width: 45px;
  height: 45px;
}

.chat .channel-info .channel-info-badge-name {
  display: grid;
  align-items: center;
  gap: 4px;
}
.chat .channel-info .channel-info-badge-name div {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.chat .channel-info .channel-info-details {
  display: grid;
  grid-template-columns: 1fr auto;
  color: var(--light-font-color);
  font-size: 12px;
  font-weight: 500;
  gap: 8px;
}
.chat .channel-info .channel-info-details > div:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat .chat-messages {
  display: grid;
  gap: 16px;
}
.chat .chat-messages-empty {
  display: grid;
  align-items: center;
  text-align: center;
  height: 75%;
}

.chat .chat-messages .chat-message:first-child:not(.chat-system) {
  margin-top: 16px;
}

.chat .chat-messages .chat-messages-buffer {
  display: grid;
  height: 16px;
}
.chat .chat-system > div {
  margin: 8px 24px;
  text-align: center;
  color: var(--light-font-color);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.chat .chat-message {
  display: grid;
  gap: 8px;
  margin: 0 16px;
}
.chat .chat-message .chat-message-text {
  color: var(--base-font-color);
  font-size: 12px;
  font-weight: 500;
}
.chat .chat-message .chat-message-bubble {
  background-position: center;
  background-size: cover;
  cursor: default;
}
.chat .chat-message .chat-message-bubble.attachment {
  cursor: pointer;
}
.chat .chat-message .chat-message-bubble.animate {
  animation-duration: 0.2s;
  animation-name: slideInAnimation;
  animation-timing-function: ease-out;
}
@keyframes slideInAnimation {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
.chat .chat-message.outgoing {
  text-align: right;
}
.chat .chat-message.outgoing .chat-message-bubble {
  display: inline-grid;
  border-radius: 12px 12px 0 12px;
  background-color: var(--primary-blue-semi);
  max-width: 80%;
}

.chat .chat-message.incoming .chat-message-bubble {
  display: inline-grid;
  border-radius: 12px 12px 12px 0;
  background-color: var(--pure-white-tint);
  max-width: 80%;
}
.chat .chat-message .chat-message-bubble > div {
  margin: 8px;
}

.chat .chat-message.outgoing .chat-message-bubble > div {
  text-align: left;
}
.chat .chat-message .chat-message-timestamp {
  color: var(--light-font-color);
  font-size: 10px;
  font-weight: 500;
}

.chat .chat-message .chat-message-sender {
  color: var(--light-font-color);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.chat .chat-message.incoming {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
}
.chat .chat-message.incoming .chat-message-content {
  display: grid;
  gap: 8px;
}
.chat .chat-channel-editor {
  display: grid;
  overflow: hidden;
  grid-template-rows: auto auto 1fr;
}
.chat .chat-channel-editor-header {
  display: grid;
  border-bottom: 1px solid var(--outline-medium-color);
}
.chat .chat-channel-editor-body {
  overflow-y: auto;
}
.chat .chat-channel-editor-header > div {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  margin: 16px;
  gap: 16px;
}

.chat .chat-channel-editor-container {
  display: grid;
  overflow: hidden;
}
.chat .chat-channel-editor-details {
  background-color: var(--darkest-bg-color);
}
.chat .chat-channel-editor-details > div {
  display: grid;
  gap: 16px;
  margin: 16px;
}
.chat .chat-channel-member-badges,
.chat .chat-channel-member-search {
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr;
  align-items: center;
}
.chat .chat-channel-member-badges-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.chat .chat-member-badge {
  display: inline-grid;
  border-radius: 6px;
  background-color: var(--primary-blue-semi);
  font-size: 12px;
  font-weight: 600;
  color: var(--base-font-color);
  text-wrap: nowrap;
}
.chat .chat-member-badge > div {
  margin: 4px 8px;
}
.chat .chat-channel-users {
  display: grid;
  gap: 16px;
}
.chat .chat-channel-user > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  margin: 0 16px;
  align-items: center;
}
.chat .chat-channel-user-buffer {
  height: 8px;
}
.chat .chat-channel-user-badge {
  opacity: 0.7;
  cursor: pointer;
  transition: all 0.25s ease;
}
.chat .chat-channel-user-badge.selected,
.chat .chat-channel-user-badge:hover {
  opacity: 1;
}

.chat .chat-message-input {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  border: 1px solid var(--outline-medium-color);
  border-radius: 8px;
  background-color: var(--input-bg-color);
}
.chat .chat-message-input input {
  border: none;
}
.chat .chat-message-input-attachment {
  display: grid;
  align-items: center;
}
.chat .chat-message-input-attachment > svg {
  margin: 0 8px;
}

.chat .close-btn {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.25s ease;
}
.chat .close-btn:hover {
  opacity: 1;
}

.chat .chat-side-panel-heading {
  color: var(--light-font-color);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.25s ease-out;
}
.chat .chat-side-panel-heading:hover {
  color: var(--base-font-color);
}
.chat .chat-side-panel-heading > div {
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 8px 24px 0 24px;
}

.chat .chat-side-panel-heading.opened .chat-side-panel-heading-toggle svg {
  rotate: 180deg;
}

.modal.chat-attachment {
  background-position: center;
  background-size: cover;
  border: none;
}
.modal.chat-attachment:focus-visible {
  outline: none;
}
.modal.chat-attachment .chat-attachment-header > div {
  display: grid;
  grid-template-columns: 1fr auto auto;
  margin: 16px;
  gap: 16px;
  align-items: center;
}
.modal.chat-attachment .chat-attachment-header button {
  padding: 6px 8px;
}
.modal.chat-attachment .chat-attachment-header .close-button {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.25s ease;
}
.modal.chat-attachment .chat-attachment-header .close-button:hover {
  opacity: 1;
}

/** Compact mode **/
.chat.compact-mode {
  background-color: var(--darker-bg-color);
}
.chat.compact-mode .chat-module {
  grid-template-columns: 1fr;
  margin: 0px;
  border-radius: 0;
}
.chat.compact-mode .chat-main-panel .chat-footer {
  background-color: var(--darker-bg-color);
}
.chat.compact-mode .chat-main-panel .chat-message-input {
  border: none;
  background-color: var(--medium-bg-color);
}
.chat.compact-mode .chat-main-panel .chat-message-input input {
  background-color: var(--medium-bg-color) !important;
}
.chat.compact-mode .chat-main-panel .chat-message-input input::placeholder {
  color: var(--light-placeholder-font);
}
