.admin-console {
  display: grid;
  background-color: var(--dark-bg-color);
  border-radius: 12px;
}

.admin-console > div {
  margin: 24px;
}
.admin-console .form-container {
  display: grid;
  max-width: 480px;
  grid-template-rows: auto auto auto 1fr;
  gap: 16px;
}

.admin-console .form-container .field-row {
  display: grid;
  gap: 8px;
}
.admin-console .form-container .field-input {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  align-items: center;
}
.admin-console .form-container button {
  padding: 8px 12px;
}
