.title-badge {
  display: inline-flex;
  border-radius: 6px;
  font-size: 12px;
  color: var(--darker-font-color);
  background-color: var(--primary-off-white);
  margin-left: 8px;
}
.title-badge > div {
  margin: 4px 6px;
}
