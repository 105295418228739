/** Crew info **/
.crew-info {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  align-items: center;
  cursor: default;
}

.crew-info .crew-info-actions {
  display: grid;
  gap: 8px;
}
.crew-info .crew-info-actions > div {
  display: grid;
}
.crew-info.show-actions {
  grid-template-columns: auto 1fr auto;
}

.crew-info .crew-info-label {
  display: grid;
  gap: 4px;
}
.crew-info .crew-info-name {
  font-size: 14px;
  font-weight: 600;
}
.crew-info .crew-info-detail {
  font-size: 12px;
  color: var(--light-font-color);
}

.crew-info .crew-info-pic {
  position: relative;
  cursor: inherit;
}
.crew-info .crew-info-pic svg {
  vertical-align: middle;
}
.crew-info .crew-info-status {
  width: 6px;
  height: 6px;
  border: 2px solid var(--outline-dark-color);
  border-radius: 6px;
  background-color: var(--bg-medium-color);
  position: absolute;
  right: 0;
  bottom: 0;
}
.crew-info .crew-info-status.hidden {
  display: none;
}
.crew-info .crew-info-status.default {
  border: 2px solid var(--base-font-color);
}
.crew-info .crew-info-status.status-0 {
  background-color: var(--primary-red);
}
.crew-info .crew-info-status.status-1,
.crew-info .crew-info-status.status-2 {
  background-color: var(--primary-green);
}
/* .crew-info .crew-info-status.status-2 {
  background-color: var(--primary-yellow);
} */
