.gate-selector {
  width: 100%;
  height: 32px;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--outline-color);
  transition: all;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}

.gate-selector.active svg {
  transform: rotate(180deg);
}
.gate-selector-value {
  display: grid;
  grid-template-columns: 1fr auto;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  text-wrap: nowrap;
  color: var(--input-font-color);
}
.gate-selector:not(.active):hover {
  background-color: var(--intermediate-bg-color);
  cursor: pointer;
}
.gate-selector.active {
  background-color: var(--light-bg-color);
  border: 1px solid var(--outline-light-color);
}
.gate-selector-value > div {
  margin: 6px 8px;
}

.gate-selector-menu {
  display: grid;
  position: absolute;
  right: -8px;
  top: 32px;
  z-index: 1;
}
.gate-selector-menu-container {
  display: grid;
  margin: 8px;
  background-color: var(--darkest-bg-color);
  border-radius: 12px;
  border: 1px solid var(--outline-color);
}
.gate-selector-menu-content {
  display: grid;
  width: 280px;
  margin: 12px;
}

.gate-selector-menu-search {
  display: grid;
  border-radius: 8px;
  border: 1px solid var(--outline-color);
  overflow: hidden;
}
.gate-selector-menu-search input {
  border-radius: 0;
}
.gate-selector-menu-search.opened {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.gate-selector-menu-search input {
  border: none;
}
.gate-selector-menu-results {
  display: grid;
  border-left: 1px solid var(--outline-color);
  border-right: 1px solid var(--outline-color);
  border-bottom: 1px solid var(--outline-color);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow-y: auto;
  scrollbar-color: var(--darkest-bg-color);
  height: 96px;
}

.gate-selector-menu-result {
  display: grid;
  background-color: var(--darker-bg-color);
  border-top: 1px solid var(--outline-color);
  color: var(--light-font-color);
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.25s ease;
}

.gate-selector-menu-result:first-child {
  border-top: none;
}

.gate-selector-menu-result:hover {
  color: var(--base-font-color);
  background-color: var(--intermediate-bg-color);
  cursor: pointer;
}
.gate-selector-menu-result > div {
  margin: 8px 10px;
}

.gate-selector-menu-actions > div {
  display: grid;
  grid-template-columns: 1fr auto auto;
  margin: 0 16px 16px 16px;
  gap: 8px;
  align-items: center;
}
