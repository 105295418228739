.allocations-module {
  display: grid;
  grid-template-rows: 80px 1fr;
  background-color: var(--dark-bg-color);
  overflow: hidden;
  height: 100%;
  gap: 0;
}

.allocations-module .allocations-module-header {
  display: grid;
  background-color: var(--light-bg-color);
}
.allocations-module .allocations-module-body {
  display: grid;
  overflow: hidden;
}

.allocations-module .allocations-module-header > div {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin: 16px;
  font-size: 14px;
  font-weight: 600;
}
.allocations-module
  .allocations-module-header
  .allocations-module-header-actions
  .close-btn {
  margin: 0;
}
.allocations-module .close-btn {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.25s ease;
}
.allocations-module .close-btn:hover {
  opacity: 1;
}
.allocations-module .allocations-module-content {
  display: grid;
  overflow: hidden;
}

.allocations-module .allocations-module-content .select-certification {
  display: grid;
  margin: 0 16px;
  align-items: start;
}

/** Overrides for the allocation (select crew module) **/
.allocations-module .select-crew .select-crew-search {
  margin: 0 16px 16px 16px;
}

.allocations-module .select-crew .select-crew-results {
  align-content: start;
  margin: 0 16px;
}

.allocations-module .select-crew .select-crew-result-row:last-child {
  margin-bottom: 16px;
}

.allocations-module .select-crew .section-label {
  font-size: 14px;
  font-weight: 500;
  margin: 24px 0 16px 0;
  color: var(--light-font-color);
}

.allocations-module .select-crew .de-emphasize {
  margin: 16px;
  text-align: center;
  font-size: 14px;
}

/** Crew assignment **/
.crew-assignment-module {
  display: grid;
  grid-template-rows: 80px 1fr 80px;
  background-color: var(--dark-bg-color);
}

.crew-assignment-module .crew-assignment-module-header {
  display: grid;
  background-color: var(--light-bg-color);
}
.crew-assignment-module .crew-assignment-module-header-title {
  display: grid;
  gap: 4px;
}

.crew-assignment-module .crew-assignment-module-header > div {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin: 0 16px;
  font-size: 14px;
  font-weight: 600;
}
.crew-assignment-module
  .crew-assignment-module-header
  .crew-assignment-module-header-actions
  .close-btn {
  margin: 0;
}
.crew-assignment-module .close-btn {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.25s ease;
}
.crew-assignment-module .close-btn:hover {
  opacity: 1;
}
.crew-assignment-module .crew-assignment-module-content {
  margin: 16px;
}
.crew-assignment-module .crew-assignment-module-content > div {
  display: grid;
  gap: 12px;
}

.crew-assignment-module .crew-assignment-module-footer {
  display: grid;
  background-color: var(--light-bg-color);
  border-top: 1px solid var(--outline-color);
}
.crew-assignment-module .crew-assignment-module-footer > div {
  display: grid;
  align-items: center;
  margin: 0 24px;
}
