.flight-stand-value .highlight-value,
.flight-time-value .highlight-positive,
.flight-time-value .highlight-negative {
  color: var(--primary-orange);
}

.flight-time-value .duration-value {
  font-size: 9px;
  font-weight: 500;
}
.flight-stand-value .previous-value,
.flight-time-value .previous-value {
  color: var(--light-font-color);
  font-size: 9px;
  font-weight: 500;
  text-decoration: line-through;
  margin-left: 4px;
}
.flight-stand-value .previous-value {
  margin-left: 0;
}
.turnaround-module-info-row .date-value {
  color: var(--light-font-color);
  font-size: 10px;
  font-weight: 500;
}

.turnaround-content .turnaround-general .flight-time-value span.value-type {
  margin-left: 4px;
  font-size: 9px;
  font-weight: 500;
}
.turnaround-content .turnaround-general .flight-time-value .previous-value {
  font-size: 12px;
}
