.tasks-overview {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}
.tasks-overview .tasks-overview-header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 24px 24px 16px 24px;
  gap: 16px;
}
.tasks-overview .tasks-overview-header .titles {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tasks-overview .tasks-overview-header .titles h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: var(--base-font-color);
}
.tasks-overview .tasks-overview-header .titles .subtitle {
  font-size: 13px;
  color: var(--light-font-color);
  text-wrap: nowrap;
}
.tasks-overview .tasks-overview-header .actions {
  display: flex;
  align-items: center;
  gap: 12px;
}
.tasks-overview .tasks-overview-header .department-label {
  font-size: 13px;
  font-weight: 500;
  color: var(--light-font-color);
}
.tasks-overview .tasks-overview-header .department-input select {
  min-width: 200px;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid var(--outline-color);
  background-color: var(--medium-bg-color);
  color: var(--base-font-color);
  font-size: 13px;
  cursor: pointer;
}
.tasks-overview .tasks-overview-header .department-input select:hover {
  border-color: var(--outline-semi-color);
}
.tasks-overview .tasks-overview-header .department-input select:focus {
  outline: none;
  border-color: var(--primary-blue);
}
.tasks-overview .tasks-overview-body {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-x: auto;
  overflow-y: hidden;
}

.tasks-overview .tasks-overview-header-row {
  display: grid;
  grid-template-columns: 240px 1fr;
  font-size: 11px;
  font-weight: 500;
  min-height: 40px;
  border-bottom: 1px solid var(--outline-color);
  position: sticky;
  top: 0;
  background-color: var(--darker-bg-color);
  z-index: 6;
}
.tasks-overview .tasks-overview-header-row > div {
  display: grid;
  align-items: center;
}
.tasks-overview .tasks-overview-header-row .side-panel-header {
  position: absolute;
  width: 240px;
  z-index: 6;
  background-color: var(--darker-bg-color);
  border-right: 1px solid var(--outline-color);
}
.tasks-overview .tasks-overview-header-row .time-marker-labels {
  margin-left: 240px;
}

.tasks-overview
  .tasks-overview-header-row
  .time-marker-labels
  > div:last-child {
  width: 50%;
}

.tasks-overview .tasks-overview-sidepanel {
  width: 240px;
  position: absolute;
  z-index: 5;
  background-color: var(--darkest-bg-color);
  border-right: 1px solid var(--outline-color);
}
.tasks-overview .tasks-overview-mainpanel {
  margin-left: 240px;
}
.tasks-overview .tasks-overview-header-row .cell-label,
.tasks-overview .tasks-overview-sidepanel .cell-label {
  display: grid;
  color: var(--base-font-color);
  height: 100%;
}
.tasks-overview .tasks-overview-sidepanel .cell-label.unassigned {
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
  color: var(--primary-red);
}

.tasks-overview .unassigned {
  transition: background-color 0.25s ease-out;
}
.tasks-overview.highlight .unassigned {
  background-color: var(--darker-bg-color);
}
.tasks-overview .tasks-overview-sidepanel .cell-label.clickable .crew-info {
  cursor: pointer;
}
.tasks-overview .tasks-overview-header-row .cell-label > div,
.tasks-overview .tasks-overview-sidepanel .cell-label > div {
  display: grid;
  margin: 0 8px;
}

.tasks-overview .tasks-overview-sidepanel .tasks-overview-crew-info {
  transition: all 0.25s ease-out;
  margin: 2px;
  border-radius: 8px;
}
.tasks-overview .tasks-overview-sidepanel .tasks-overview-crew-info:hover {
  transition: all 0.25s ease-out;
  background-color: var(--medium-bg-color);
}
.tasks-overview
  .tasks-overview-sidepanel
  .tasks-overview-crew-info.highlight-warning {
  background-color: var(--primary-yellow-semi);
  border: 1px solid var(--primary-yellow-dim);
}

.tasks-overview .tasks-overview-header-row .cell {
  display: grid;
  /* border-right: 1px solid var(--outline-color); */
  color: var(--light-font-color);
  font-weight: 600;
  height: 100%;
  transform: translate(50%, 0);
}
.tasks-overview .tasks-overview-header-row > div:last-child {
  border-right: 1px solid var(--outline-darker-color);
}
.tasks-overview .tasks-overview-header-row .cell > div {
  display: grid;
  align-items: center;
  text-align: center;
}

.tasks-overview .tasks-overview-container {
  display: grid;
  grid-template-rows: auto 1fr;
  align-content: start;
  /* display: inline-grid;
  align-items: start; */
  border: 1px solid var(--outline-color);
  margin: 0 24px 24px 24px;
  overflow-x: auto;
  overflow-y: auto;
  min-width: 1000px;
  position: relative;
}

.tasks-overview .tasks-overview-content {
  display: grid;
  grid-template-columns: auto 1fr;
  position: relative;
}

.tasks-overview .tasks-overview-sidepanel,
.tasks-overview .tasks-overview-mainpanel,
.tasks-overview .tasks-overview-gridlines {
  display: grid;
}
.tasks-overview .tasks-overview-gridlines > div {
  display: grid;
  min-height: 45px;
}

.tasks-overview .tasks-overview-gridlines .gridcell {
  border-right: 1px solid var(--outline-darker-color);
}
.tasks-overview .tasks-overview-sidepanel > div {
  display: grid;
  align-content: center;
  font-size: 11px;
  font-weight: 500;
  min-height: 45px;
}

.tasks-overview .tasks-overview-mainpanel {
  display: grid;
}
.tasks-overview .tasks-overview-timelinebars > div {
  display: grid;
  position: relative;
  align-content: center;
  min-height: 45px;
  align-items: center;
}

.tasks-overview .tasks-overview-timelinebars {
  height: 100%;
}
.tasks-overview .tasks-overview-timelinebar,
.tasks-overview .tasks-overview-timelinebars {
  display: grid;
  position: absolute;
}

.tasks-overview .tasks-overview-timelinebar {
  display: grid;
  font-size: 11px;
  font-weight: 600;
  text-wrap: nowrap;
  height: 24px;
  background-color: var(--primary-blue);
  border-radius: 6px;
  opacity: 0.9;
  transition: all 0.25s ease-out;
  color: var(--medium-font-color);
  overflow: hidden;
  cursor: pointer;
  z-index: 2;
}
.tasks-overview .tasks-overview-timelinebar.crewshift {
  cursor: inherit;
}
.tasks-overview .hovered {
  transition: background-color 0.25s ease-out;
  background-color: var(--darker-bg-color);
}
.tasks-overview .tasks-overview-timelinebar.completed {
  background-color: var(--dark-bg-color);
  border: 1px solid var(--outline-color);
  color: var(--dark-font-color);
  cursor: pointer;
}
.tasks-overview .tasks-overview-timelinebar.completed:hover {
  background-color: var(--dark-bg-color);
  color: var(--medium-font-color);
  cursor: pointer;
}
.tasks-overview .tasks-overview-timelinebar.unassigned {
  background-color: var(--medium-bg-color);
  border: 1px solid var(--primary-red);
  color: var(--primary-red-dim);
  cursor: pointer;
}
.tasks-overview .tasks-overview-timelinebar.unassigned:hover {
  color: var(--primary-red);
}

.tasks-overview .tasks-overview-timelinebar.not-confirmed {
  opacity: 0.25;
}
.tasks-overview .tasks-overview-timelinebar.not-confirmed:hover {
  opacity: 0.5;
}
.tasks-overview .tasks-overview-timelinebar.overlapping {
  border: 1px solid var(--primary-yellow);
}

.tasks-overview .tasks-overview-timelinebar.crewshift {
  /* border: 2px solid var(--outline-dark-color); */
  background-color: var(--light-bg-color);
  z-index: 1;
  opacity: 0.5;
}
.tasks-overview .tasks-overview-timelinebar.crewshift:hover {
  /* border: 2px solid var(--outline-semi-color); */
  z-index: 1;
  opacity: 0.9;
}
.tasks-overview .tasks-overview-timelinebar.point {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: -50%;
  border: 2px solid var(--outline-color);
}
.tasks-overview .tasks-overview-timelinebar.point:hover {
  border: 2px solid var(--outline-light-color);
}
.tasks-overview .tasks-overview-timelinebar.unassigned.point {
  border: 2px solid var(--primary-red);
}
.tasks-overview .tasks-overview-timelinebar.overlapping.point {
  border: 2px solid var(--primary-yellow);
}

.tasks-overview .tasks-overview-timelinebar:hover {
  z-index: 3;
  opacity: 1;
  color: var(--base-font-color);
  /* overflow: visible; */
  filter: drop-shadow(2px 4px 6px var(--drop-shadow-strong-color));
}
.tasks-overview .tasks-overview-timelinebar > div {
  display: grid;
  position: relative;
  align-items: center;
}

.tasks-overview .tasks-overview-timelinebar-value {
  display: grid;
  position: absolute;
  align-items: center;
  margin: 0 8px;
}

.tasks-overview
  .tasks-overview-timelinebar.completed
  .tasks-overview-timelinebar-value,
.tasks-overview
  .tasks-overview-timelinebar.started
  .tasks-overview-timelinebar-value {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px;
  align-items: center;
}
.tasks-overview
  .tasks-overview-timelinebar.completed
  .tasks-overview-timelinebar-value
  > div,
.tasks-overview
  .tasks-overview-timelinebar.started
  .tasks-overview-timelinebar-value
  > div {
  display: grid;
  align-items: center;
}

.tasks-overview
  .tasks-overview-timelinebar:hover
  .tasks-overview-timelinebar-value
  .indicator {
  opacity: 1;
}

.tasks-overview
  .tasks-overview-timelinebar.completed
  .tasks-overview-timelinebar-value
  svg {
  width: 16px;
  height: 16px;
}
.tasks-overview
  .tasks-overview-timelinebar.started
  .tasks-overview-timelinebar-value
  .pulse {
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--pure-white-dim);
  border-radius: 50%;
  margin: 2px;
  transition: all 0.25s ease-out;
}

.tasks-overview
  .tasks-overview-timelinebar:hover.started
  .tasks-overview-timelinebar-value
  .pulse {
  background-color: var(--pure-white);
}

/** Markers **/
.tasks-overview .tasks-overview-timeline-markers {
  position: absolute;
  z-index: 3;
  width: auto;
  height: 100%;
  margin-left: 240px;
}

/** Timeline marker **/
.tasks-overview .timeline-marker {
  display: grid;
  position: absolute;
  width: 0px;
  height: 100%;
  transition: opacity 0.25s ease-out;
}

.tasks-overview .timeline-marker.point-marker {
  pointer-events: none;
  border-left: none;
}

.tasks-overview .timeline-marker.point-marker .timeline-marker-line,
.tasks-overview .timeline-marker.point-marker:hover .timeline-marker-line {
  border-left: 1px dashed var(--primary-blue-semi);
  border-right: 1px dashed var(--primary-blue-semi);
}
.tasks-overview .timeline-marker .timeline-marker-line {
  border-left: 1px dashed var(--pure-white-tint);
  border-right: 1px dashed var(--pure-white-tint);
  transition: all 0.25s ease-out;
  margin-left: -1px;
}

.tasks-overview .timeline-marker.hovered .timeline-marker-line {
  border-left: 1px dashed var(--pure-white-dim);
  border-right: 1px dashed var(--pure-white-dim);
}
.tasks-overview .timeline-marker > div {
  display: grid;
  grid-template-rows: 42px 1fr;
  position: relative;
}
.tasks-overview .timeline-marker-label-anchor {
  display: grid;
  height: 42px;
  position: relative;
}
.tasks-overview .timeline-marker .timeline-marker-label {
  display: grid;
  position: absolute;
  /* background-color: var(--overlay-gray-color); */
  background-color: var(--medium-bg-color);
  border: 1px solid var(--outline-color);
  border-radius: 4px;
  transform: translate(-50%, calc(-50% - 2px));
  top: 50%;
  text-align: center;
  cursor: default;
  z-index: 3;
  transition: all 0.25s ease-out;
}
.tasks-overview .timeline-marker.hovered .timeline-marker-label {
  border: 1px solid var(--outline-light-color);
}

.tasks-overview .timeline-marker.point-marker .timeline-marker-label {
  background-color: var(--primary-blue);
}

.tasks-overview .timeline-marker-label > div {
  display: grid;
  margin: 4px 6px;
  text-transform: uppercase;
  text-wrap: nowrap;
}
.tasks-overview .timeline-marker-label-title {
  font-size: 9px;
  font-weight: 500;
  color: var(--light-font-color);
}
.tasks-overview .timeline-marker-label-value {
  font-size: 12px;
  font-weight: 600;
  color: var(--base-font-color);
}

.tasks-overview .unassigned-banner {
  display: grid;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-red);
  border: 1px solid var(--primary-red);
  border-radius: 8px;
  cursor: pointer;
  margin: 0 16px;
  text-wrap: nowrap;
  background-color: var(--dark-bg-color);
  opacity: 0.75;
  transition: all 0.25s ease-out;
}
.tasks-overview .unassigned-banner span {
  display: grid;
  font-size: 12px;
  font-weight: 500;
}
.tasks-overview .unassigned-banner:hover {
  opacity: 1;
}
.tasks-overview .unassigned-banner > div {
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 8px 12px;
  align-items: baseline;
  gap: 4px;
}

/** Crew info overrides **/
.tasks-overview .crew-info-name {
  font-size: 12px;
}

/** Task modal **/
.task-modal-content {
  display: grid;
  margin: 0 24px;
  color: var(--base-font-color);
  align-content: start;
  gap: 16px;
}

.task-modal-content .task-modal-header {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
}
.task-modal-content .task-modal-header h2 {
  font-size: 16px;
  font-weight: 600;
  color: var(--base-font-color);
  margin-bottom: 4px;
}
.task-modal-content .task-modal-header svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-right: 4px;
}
.task-modal-content .task-modal-slots-container {
  display: grid;
  gap: 16px;
}
.task-modal-content .task-modal-slots {
  display: grid;
  gap: 8px;
}

.task-modal-content .task-modal-slots-name {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;
}
.task-modal-content .task-modal-slots-counts {
  display: grid;
  font-size: 13px;
  font-weight: 600;
  gap: 8px;
  grid-template-columns: auto auto;
}
.task-modal-content .task-modal-slots-counts span {
  color: var(--light-font-color);
  font-size: 12px;
  font-weight: 500;
  text-transform: lowercase;
  margin-left: 0.25em;
}

.modal.crew-details-modal .modal-container,
.modal.crew-details-modal .crew-details-body {
  overflow-y: hidden;
}

.crew-details-content {
  display: grid;
  margin: 0 24px;
  color: var(--base-font-color);
  align-content: start;
  gap: 16px;
  grid-template-rows: auto auto 1fr;
  overflow-y: hidden;
}
.crew-details-content > div {
  display: grid;
}

.crew-details-content .crew-details-header {
  font-size: 12px;
  font-weight: 500;
  color: var(--base-font-color);
  text-transform: capitalize;
}
.crew-details-content .crew-details-module {
  display: grid;
  border-radius: 12px;
  border: 1px solid var(--outline-color);
  overflow: hidden;
}
.crew-details-content .crew-shift-list,
.crew-details-content .crew-cert-list,
.crew-details-content .crew-tasks-list {
  display: grid;
  align-content: start;
  overflow-y: auto;
}
.crew-details-content .crew-details-header {
  display: grid;
  margin: 8px 0;
}
.crew-details-content .crew-details-body {
  display: grid;
  gap: 8px;
  align-content: start;
}
.crew-details-content .crew-details-header h2 {
  font-size: 16px;
  font-weight: 500;
}

.crew-details-content .crew-shift-row,
.crew-details-content .crew-cert-row,
.crew-details-content .crew-task-row {
  transition: all 0.25s ease-out;
  background-color: var(--dark-bg-color);
}
.crew-details-content .crew-shift-row:hover,
.crew-details-content .crew-cert-row:hover,
.crew-details-content .crew-task-row:hover {
  background-color: var(--medium-bg-color);
}
.crew-details-content .crew-shift-row > div,
.crew-details-content .crew-cert-row > div,
.crew-details-content .crew-task-row > div {
  font-size: 14px;
  font-weight: 500;
  margin: 12px 16px;
}
.crew-details-content .crew-shift-row {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 8px;
}
.crew-details-content .crew-shift-row .empty-state {
  grid-column: span 2;
}

.crew-details-content .crew-task-row {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 8px;
  align-items: center;
}
.crew-details-content .crew-task-row {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 8px;
  align-items: center;
}
.crew-details-content .crew-task-row .crew-task-row-details {
  display: grid;
  grid-template-rows: auto 1fr;
}

.crew-details-content .crew-task-row .crew-task-row-details span {
  color: var(--light-font-color);
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}
.crew-details-content .crew-task-row .crew-task-row-details.in-progress span {
  color: var(--primary-yellow);
}
.crew-details-content .crew-task-row .crew-task-row-details.completed span {
  color: var(--primary-green);
}

.crew-details-content .sub-tabs {
  margin: 0;
}

.crew-details-content .crew-details-warning-banner {
  border: 1px solid var(--primary-yellow-dim);
  background-color: var(--primary-yellow-semi);
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
}
.crew-details-content .crew-details-warning-banner > div {
  margin: 10px 12px;
}

/** Filters **/
.live-roster-filters > div {
  display: grid;
  position: relative;
}
.live-roster-filters .live-roster-filter-menu {
  display: none;
  position: absolute;
  top: 48px;
  right: 0;
  z-index: 10;
  width: 280px;
  background-color: var(--dark-bg-color);
  border-radius: 8px;
  border: 1px solid var(--outline-color);
}
.live-roster-filters.open .live-roster-filter-menu {
  display: grid;
  max-height: 320px;
  overflow-y: auto;
}
.live-roster-filters .live-roster-filter-menu > div {
  margin: 8px;
}

.live-roster-filters .live-roster-filter-rows {
  display: grid;
}
.live-roster-filters .live-roster-filter-row-divider {
  display: grid;
}
.live-roster-filters .live-roster-filter-row-divider > div {
  margin: 8px 0;
}
.live-roster-filters .live-roster-filter-row {
  display: grid;
  color: var(--light-font-color);
  font-size: 14px;
  font-weight: 500;
  transition: all 0.25s ease-out;
  border-radius: 8px;
  cursor: pointer;
}
.live-roster-filters .live-roster-filter-row:hover {
  background-color: var(--semi-transparent);
  color: var(--base-font-color);
}
.live-roster-filters .live-roster-filter-row.selected {
  color: var(--base-font-color);
}
.live-roster-filters .live-roster-filter-row > div {
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 8px;
  gap: 4px 8px;
  align-items: center;
}
.live-roster-filters .live-roster-filter-row span {
  font-size: 12px;
  text-transform: lowercase;
}
.live-roster-filters .live-roster-filter-row .checkbox-container {
  display: grid;
}
