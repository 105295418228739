.templates {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  overflow: hidden;
}
.templates h3 {
  margin: 32px 0 16px 0;
}
.templates .template-editor-side-panel {
  display: grid;
  grid-template-rows: auto 1fr;
}
.templates .templates-container {
  display: grid;
  gap: 16px;
  overflow: auto;
}
.templates .templates-content {
  margin: 0 24px;
}
.templates .templates-content-header {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  align-items: center;
}
.templates .listing-header,
.templates .listing-row {
  grid-template-columns: 1fr 1fr 1fr 96px;
}

.templates .listing-row .actions {
  text-align: left;
}
.template-editor {
  display: grid;
}
.template-editor > div {
  display: grid;
  gap: 24px;
  margin: 24px;
}
.template-editor .template-editor-toolbar {
  display: grid;
}
.template-editor .template-editor-toolbar h2 {
  font-size: 24px;
  font-weight: 600;
}
.template-editor .template-editor-toolbar button {
  line-height: 18px;
}

.template-editor .template-editor-toolbar > div {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 16px;
}
.template-editor .template-editor-content {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
}
.template-editor .module {
  display: grid;
  background-color: var(--darker-bg-color);
  border-radius: 12px;
}

.template-editor .module > div {
  display: grid;
  margin: 24px;
  gap: 16px;
}
.template-editor h3 {
  font-size: 18px;
  font-weight: 600;
  min-width: 240px;
  margin: 0;
}
.template-editor .side-panel-item {
  display: grid;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.25s ease;
}

.template-editor .side-panel-item:hover {
  background-color: var(--intermediate-bg-color);
}
.template-editor .side-panel-item.selected {
  background-color: var(--primary-blue);
}
.template-editor .side-panel-item label {
  font-size: 14px;
  font-weight: 600;
  color: var(--light-font-color);
  cursor: inherit;
}
.template-editor .side-panel-item.selected label,
.template-editor .side-panel-item:hover label {
  color: var(--base-font-color);
}
.template-editor .side-panel-item > div {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  margin: 12px;
  align-items: center;
}

.template-editor .template-editor-main-panel {
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 24px;
}

.template-editor .main-fields {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 8px;
}

.template-editor .main-fields .field-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
.template-editor .main-fields .field-values {
  display: grid;
  gap: 8px;
}
.template-editor .resource-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.template-editor .resource-fields .module > div {
  grid-template-rows: auto auto 1fr;
}
.template-editor .resource-fields-header {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
  font-size: 14px;
  font-weight: 500;
  text-wrap: nowrap;
}

.template-editor .start-time-offset {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 8px;
  align-items: center;
}
.template-editor .operation-duration {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;
}

.template-editor .template-timeline {
  display: grid;
  grid-template-rows: 1fr;
  border: 1px solid var(--outline-medium-color);
  position: relative;
}
.template-editor .template-timeline-panels {
  display: grid;
  grid-template-columns: auto 1fr;
}
.template-editor .template-timeline-side-panel {
  border-right: 1px solid var(--outline-medium-color);
}
.template-editor .template-timeline-side-panel > div {
  display: grid;
  gap: 16px;
  margin: 66px 16px 16px 16px;
}
.template-editor .template-timeline-left-label {
  display: grid;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  height: 24px;
}
.template-editor .timeline-module > div {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 16px;
}
.template-editor .timeline-header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.template-editor .timeline-header .timeline-header-fields {
  display: grid;
  grid-template-columns: auto 64px auto;
  align-items: center;
  gap: 8px;
}
.template-editor .timeline-header .timeline-header-info {
  font-size: 14px;
  text-align: right;
}
.template-editor .timeline-body {
  display: grid;
}
.template-editor .resource-fields-rows {
  display: grid;
  gap: 8px;
}
.template-editor .resource-fields-row {
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  font-weight: 600;
}

.template-editor .timeline-grid {
  display: grid;
  height: 100%;
  position: relative;
  grid-template-rows: auto 1fr;
  overflow: hidden;
}

.template-editor .timeline-grid-row {
  display: grid;
  height: 100%;
  border-bottom: 1px solid var(--outline-medium-color);
}
.template-editor .timeline-grid-row span {
  margin: 16px 0 16px -100%;
  font-size: 14px;
  color: var(--light-font-color);
}
.template-editor .timeline-grid-row span.start {
  margin: 16px 0 16px 0;
}
.template-editor .timeline-grid-row > div {
  display: grid;
  border-right: 1px solid var(--outline-medium-color);
  height: 100%;
  text-align: center;
}

.template-editor .timeline-content {
  position: absolute;
  z-index: 1;
}

.template-editor .timeline-bars {
  display: grid;
  gap: 16px;
}
.template-editor .timeline-bar {
  display: grid;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: var(--light-bg-color);
  text-wrap: nowrap;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
  cursor: default;
  position: relative;
}
.template-editor .timeline-bar.selected {
  background-color: var(--primary-blue);
}
.template-editor .timeline-bar-top {
  display: grid;
  height: 50px;
}
.template-editor .timeline-bar-bottom {
  display: grid;
  height: 8px;
}
.template-editor .timeline-bar .timeline-label {
  color: var(--light-font-color);
  margin-left: 8px;
}
.template-editor .timeline-bar.selected .timeline-label,
.template-editor .timeline-bar:hover .timeline-label {
  color: var(--base-font-color);
}
.template-editor .timeline-bar.no-duration {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  background-color: transparent;
}
.template-editor .timeline-bar.no-duration .timeline-marker {
  width: 8px;
  height: 8px;
  border: 2px solid var(--light-font-color);
  background-color: var(--medium-bg-color);
  border-radius: 50%;
  margin-left: -6px;
}
.template-editor .timeline-bar.no-duration.selected .timeline-marker {
  background-color: var(--primary-blue);
  border: 2px solid var(--base-font-color);
}

.template-editor .timeline-ref-line {
  width: 1px;
  height: 100%;
  background-color: var(--outline-light-color);
  position: absolute;
  opacity: 0.5;
  cursor: default;
}

.template-editor .timeline-ref-line:hover {
  opacity: 1;
  transition-duration: 0.5;
}

.template-editor .timeline-ref-line > div {
  position: relative;
}

.template-editor .timeline-ref-line .timeline-ref-label {
  display: grid;
  position: absolute;
  transform: translate(calc(-100% - 4px), 4px);
  text-wrap: nowrap;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--medium-bg-color);
  border-radius: 4px;
}

.template-editor .timeline-ref-line .timeline-ref-label > div {
  padding: 4px 6px;
}

.template-editor .module .turnaround-review-module {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto auto auto;
  gap: 32px;
}
.template-editor .module .badge {
  display: grid;
  align-items: center;
  background-color: var(--primary-green-semi);
  color: var(--base-font-color);
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
}
.template-editor .module .badge > div {
  color: var(--primary-green);
  margin: 4px 8px;
}
.template-editor .module .label-value-pair {
  display: grid;
  gap: 4px;
  font-size: 14px;
}

.template-editor .template-review .listing-header,
.template-editor .template-review .listing-row {
  grid-template-columns: 3fr repeat(3, minmax(0, 2fr)) 1fr;
}

.template-editor .template-review .offset-type-2 {
  background-color: var(--darker-bg-color);
}
.template-editor .template-review .listing-row:hover {
  background-color: var(--highlight-dark-bg-color);
}

/** Diagram **/
.module.diagram {
  background-position: center;
  background-size: cover;
}
.template-diagram {
  overflow: hidden;
  height: 680px;
}
.template-diagram .template-diagram-content {
  position: relative;
  height: 720px;
  margin-top: -32px;
}
.template-diagram .template-diagram-details {
  position: absolute;
  top: 32px;
  right: 16px;
  border-radius: 12px;
  background-color: var(--overlay-color);
  min-width: 200px;
  opacity: 0;
  transition: all 0.25s ease;
}
.template-diagram .template-diagram-details.active {
  opacity: 1;
}
.template-diagram .template-diagram-details > div {
  margin: 16px;
  font-size: 14px;
  font-weight: 600;
}
.template-diagram .template-diagram-details .template-diagram-details-row {
  display: grid;
  font-weight: 500;
  grid-template-columns: 1fr auto;
  gap: 8px;
}
.template-diagram .template-diagram-layer {
  position: absolute;
  background-position: center;
  background-size: cover;
}
.template-diagram .template-diagram-layer.gse {
  z-index: 2;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  opacity: 0.4;
  filter: grayscale(100%);
  transition: all 0.25s ease;
  cursor: pointer;
}
.template-diagram .template-diagram-layer.gse.active {
  filter: grayscale(0);
  opacity: 1;
}
.template-diagram .template-diagram-layer.gse.hovered {
  filter: grayscale(0);
  opacity: 1;
}
.template-diagram .template-diagram-layer.overlay {
  z-index: 10;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
}
.template-diagram .template-diagram-layer.underlay {
  z-index: 1;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: all 0.25s ease;
}
.template-diagram .template-diagram-layer.underlay.hovered {
  opacity: 1;
}
.template-diagram .template-diagram-layer.aircraft {
  z-index: 3;
  width: 720px;
  height: 720px;
  left: 50%;
  transform: translate(-50%);
  filter: drop-shadow;
}
.template-diagram .template-diagram-layer.lower-deck-loader {
  transform: translate(calc(-50% + -180px), -110px);
}
.template-diagram .template-diagram-layer.belt-loader {
  transform: translate(calc(-50% + 140px), -100px);
}
.template-diagram .template-diagram-layer.main-deck-loader {
  transform: translate(calc(-50% + 85px), -100px);
}
.template-diagram .template-diagram-layer.pushback-tug {
  transform: translate(calc(-50% + -320px), -35px);
}
.template-diagram .template-diagram-layer.cabin-cleaning-truck {
  width: 80px;
  height: 80px;
  transform: translate(calc(-50% + 200px), 30px) rotate(-15deg);
}
.template-diagram .template-diagram-layer.ac-truck {
  width: 75px;
  height: 75px;
  transform: translate(calc(-50% + -120px), 10px) rotate(45deg);
}
.template-diagram .template-diagram-layer.potable-water-truck {
  width: 80px;
  height: 80px;
  transform: translate(calc(-50% + 150px), 20px) rotate(-15deg);
}
.template-diagram .template-diagram-layer.lavatory-truck {
  width: 80px;
  height: 80px;
  transform: translate(calc(-50% + 240px), 15px) rotate(90deg);
}
.template-diagram .template-diagram-layer.baggage-tractor {
  width: 130px;
  height: 130px;
  transform: translate(calc(-50% + 120px), -190px);
}
.template-diagram .template-diagram-layer.utility-vehicle {
  width: 130px;
  height: 130px;
  transform: translate(calc(-50% + -180px), -190px);
}
.template-diagram .template-diagram-layer.catering-truck {
  width: 75px;
  height: 75px;
  transform: translate(calc(-50% + -240px), -90px);
}
.template-diagram .template-diagram-layer.bobtail-truck {
  width: 60px;
  height: 60px;
  transform: translate(calc(-50% + -140px), -80px) rotate(135deg);
}
.template-diagram .template-diagram-layer.air-start-unit {
  width: 60px;
  height: 60px;
  transform: translate(calc(-50% + -220px), 120px) rotate(25deg);
}
.template-diagram .template-diagram-layer.cargo-tractor {
  width: 80px;
  height: 80px;
  transform: translate(calc(-50% + 190px), -90px) rotate(100deg);
}
.template-diagram .template-diagram-layer.ground-power-unit {
  width: 60px;
  height: 60px;
  transform: translate(calc(-50% + -290px), -70px) rotate(45deg);
}
.template-diagram .template-diagram-layer.fuel-truck {
  width: 120px;
  height: 120px;
  transform: translate(calc(-50% + -50px), 130px) rotate(-20deg);
}
.template-diagram .template-diagram-layer.maintenance {
  width: 80px;
  height: 80px;
  transform: translate(calc(-50% + 90px), 40px) rotate(45deg);
}
.template-diagram .template-diagram-layer.stairs {
  width: 80px;
  height: 80px;
  transform: translate(calc(-50% + -180px), 30px);
}
.template-diagram .template-diagram-layer.gse.stairs {
  z-index: 3;
}
.template-diagram .template-diagram-layer.stair-truck {
  width: 80px;
  height: 80px;
  transform: translate(calc(-50% + -220px), 25px);
}
.template-diagram .template-diagram-layer.gse.stair-truck {
  z-index: 3;
}
.template-diagram .template-diagram-layer.garbage-truck {
  width: 80px;
  height: 80px;
  transform: translate(calc(-50% + 230px), -80px) rotate(125deg);
}

.template-diagram .template-diagram-layer.admin {
  width: 40px;
  height: 40px;
  transform: translate(calc(-50% + 100px), 140px);
}
.template-diagram .template-diagram-layer.other {
  width: 40px;
  height: 40px;
  transform: translate(calc(-50% + 150px), 140px);
}
.template-diagram .template-diagram-layer.extra {
  width: 40px;
  height: 40px;
  transform: translate(calc(-50% + 200px), 140px);
}
.template-diagram .template-diagram-layer.engineering {
  width: 80px;
  height: 80px;
  transform: translate(calc(-50% + -240px), 150px) rotate(-65deg);
}

/** Aircraft selector **/
.template-aircraft-selector {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 16px;
}
.template-aircraft-selector .templates-aircraft-tiles {
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  min-width: 1080px;
  grid-template-rows: auto 1fr;
}
.template-aircraft-selector .template-aircraft-tile {
  display: grid;
  background-color: var(--darker-bg-color);
  border-radius: 12px;
  transition: all 0.25s ease;
  border: 1px solid transparent;
  cursor: pointer;
  opacity: 0.75;
  color: var(--light-font-color);
  transition: all 0.25s ease;
}

.template-aircraft-selector .template-aircraft-tile:hover {
  border: 1px solid var(--primary-blue);
  opacity: 1;
  color: var(--base-font-color);
}

.template-aircraft-selector .template-aircraft-tile > div {
  display: grid;
  margin: 16px;
  gap: 16px;
}
.template-aircraft-selector .template-aircraft-image-container {
  overflow: hidden;
  background-color: var(--tile-color);
  border-radius: 8px;
}
.template-aircraft-selector .template-aircraft-image {
  width: 100%;
  height: 140px;
  background-position: center;
  background-size: cover;
  transform: scale(0.85);
  filter: grayscale(100%) drop-shadow(0 0 0.75rem var(--pure-black));
  transition: all 0.25s ease-out;
}

.template-aircraft-selector
  .template-aircraft-tile:hover
  .template-aircraft-image {
  transform: scale(0.9);
  filter: grayscale(0);
}

.template-aircraft-selector .template-aircraft-info {
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.template-aircraft-selector .template-aircraft-info span {
  font-size: 12px;
  font-weight: 500;
}
