.main-console {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100vh;
  background-color: var(--darkest-bg-color);
  position: relative;
  transition: all 0.25s ease-out;
}
.main-console.compact {
  grid-template-columns: 64px 1fr;
}
.main-console .main-menu {
  background-color: var(--dark-bg-color);
  min-width: 32px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  overflow: hidden;
}
.main-console .main-header {
  min-height: 32px;
}
.main-console .main-header {
  display: grid;
  grid-template-columns: 1fr auto auto;
  min-height: 32px;
  gap: 8px;
}

.main-header .header-left {
  display: grid;
  align-items: center;
}
.main-header .header-center {
  display: grid;
  align-items: center;
}

.main-header .header-right {
  display: grid;
  margin: 8px 16px;
  gap: 12px;
}

.main-console .main-container {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: hidden;
  position: relative;
}

.main-console .main-side-panel {
  display: grid;
  overflow-x: visible;
  overflow-y: hidden;
  height: 100%;
}

.main-console .main-content-box {
  overflow-y: auto;
  scrollbar-color: var(--scrollbars);
}

.main-console .internal-badge {
  border: 1px solid var(--base-font-color);
  color: var(--base-font-color);
  border-radius: 6px;
  padding: 4px 8px;
  display: inline-grid;
  font-size: 10px;
  text-transform: uppercase;
  vertical-align: middle;
  margin-left: 16px;
}
