.turnaround-card {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 8px;
  border-radius: 14px;
  border: 1px solid var(--outline-color);
  padding: 24px;
  background-color: var(--darker-bg-color);
  cursor: pointer;
  transition: background-color 0.25s ease;
}
.turnaround-card.status-400 {
  border: 2px solid var(--primary-red);
}
.turnaround-card.status-201 {
  border: 1px solid var(--primary-yellow);
}
.turnaround-card:hover {
  background-color: var(--intermediate-bg-color);
}

.turnaround-card .turnaround-section {
  display: grid;
  grid-template-columns: auto repeat(2, minmax(0, 2fr)) repeat(
      3,
      minmax(0, 3fr)
    );
  gap: 16px;
}

.turnaround-card .icon {
  display: grid;
  align-items: center;
}
.turnaround-card .info-box {
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 8px;
  align-items: center;
}
.turnaround-card .info-box .icon {
  display: grid;
  align-content: center;
}

.turnaround-card .info-box span {
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-transform: uppercase;
}
.turnaround-card .info-box h3 {
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.turnaround-card .info-box.outlined {
  grid-template-rows: auto 1fr;
  border-radius: 8px;
  border: 1px solid var(--outline-color);
  gap: 8px;
}

.turnaround-card .info-box.outlined span {
  font-size: 12px;
  font-weight: 500;
}
.turnaround-card .info-box.outlined h3 {
  font-size: 17px;
  font-weight: 600;
}

.turnaround-card .turnaround-progress-row {
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 6px 0 12px 0;
  gap: 8px;
}

.turnaround-card .turnaround-progress-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 12px;
}

.turnaround-card .turnaround-progress-container span {
  font-size: 12px;
  font-weight: 600;
}

.turnaround-card .turnaround-progress-header {
  display: grid;
  grid-template-columns: 1fr auto;
}
.turnaround-card .progress-bar {
  width: 100%;
  background-color: var(--light-bg-color);
  height: 7px;
  border-radius: 1rem;
  position: relative;
}
.turnaround-card .progress-bar-filled {
  width: 50%;
  height: 100%;
  position: absolute;
  border-radius: 1rem;
}

.turnaround-card.in-progress .progress-bar-filled {
  background-color: var(--primary-yellow);
}
.turnaround-card.completed .progress-bar-filled {
  background-color: var(--primary-green);
}
