.search-input-container {
  display: grid;
  position: relative;
  border: 1px solid var(--outline-color);
  border-radius: 8px;
  align-items: center;
  margin: 0 16px;
  transition: border 0.25s ease;
}
.search-input-container.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.search-input-container:not(.disabled).focused {
  border: 1px solid var(--primary-blue);
}
.search-input-container svg {
  position: absolute;
  left: 4px;
}
.search-input-container input {
  border: none;
  color: var(--medium-font-color) !important;
  background-color: transparent !important;
  border-radius: 0;
  font-size: 14px;
  margin: 4px 0;
  padding: 6px 8px 6px 32px;
  outline: none;
}

.search-input-container input::placeholder {
  color: var(--medium-font-color) !important;
  opacity: 0.5;
}
