.unsupported-browser {
  margin: 24px;
  border: 1px solid var(--outline-color);
  border-radius: 12px;
}
.unsupported-browser > div {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  margin: 24px;
}
.unsupported-browser h3 {
  display: grid;
  font-size: 18px;
}
.unsupported-browser span {
  font-size: 14px;
  font-weight: 500;
  color: var(--medium-font-color);
}
