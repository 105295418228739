.alert-badge {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
}

.alert-badge span {
  font-size: 14px;
  font-weight: 500;
}
.alert-badge > span,
.alert-badge > svg {
  margin: auto 0;
}
