/* Button switch */
.button-switch {
  display: inline-grid;
  border-radius: 8px;
  border: 1px solid var(--outline-color);
  width: 560px;
}

.button-switch.wide {
  width: 280px;
}

.button-switch .button-switch-box {
  margin: 3px;
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 4px;
}
.button-switch .button-switch-option {
  display: grid;
  align-items: center;
  border-radius: 6px;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  cursor: pointer;
}

.button-switch.disabled .button-switch-option {
  opacity: 0.5;
  cursor: not-allowed;
}

.button-switch:not(.disabled) .button-switch-option:not(.selected):hover {
  background-color: var(--darker-bg-color);
}
.button-switch.light .button-switch-option:hover {
  background-color: var(--medium-bg-color);
}
.button-switch .button-switch-option.selected {
  background-color: var(--medium-bg-color);
}
.button-switch.light .button-switch-option.selected {
  background-color: var(--pure-white);
}

.button-switch .button-switch-option span {
  color: var(--light-font-color);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin: 8px 16px;
}
.button-switch.light .button-switch-option span {
  color: var(--medium-font-color);
}
.button-switch .button-switch-option:hover span,
.button-switch .button-switch-option.selected span {
  color: var(--base-font-color);
}
.button-switch.light .button-switch-option:hover span,
.button-switch.light .button-switch-option.selected span {
  color: var(--primary-blue);
}

/* Disabled state */
.button-switch.disabled .button-switch-option.selected span,
.button-switch.disabled .button-switch-option:hover span {
  color: var(--light-font-color);
  cursor: not-allowed;
}

.button-switch .button-switch-option.subvalues {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.button-switch .button-switch-subvalues > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2px 8px;
  gap: 4px;
}
.button-switch .button-switch-subvalue {
  display: grid;
  font-size: 12px;
  border-radius: 6px;
  opacity: 0.7;
  cursor: pointer;
}
.button-switch .button-switch-subvalue.active:hover {
  background-color: var(--darker-bg-color);
  opacity: 1;
}
.button-switch .button-switch-subvalue.critical:hover {
  background-color: var(--primary-red);
  opacity: 1;
}
.button-switch .button-switch-subvalue.active.selected {
  background-color: var(--darker-bg-color);
  opacity: 1;
}
.button-switch .button-switch-subvalue.critical.selected {
  background-color: var(--primary-red);
  opacity: 1;
}
.button-switch .button-switch-subvalue > div {
  display: grid;
  margin: 4px 8px;
}
