.option-box {
  display: grid;
  border: 1px solid var(--outline-color);
  border-radius: 8px;
  grid-template-rows: auto 1fr;
  overflow: hidden;
}
.option-box .option-box-header {
  background-color: var(--alternate-blue);
  cursor: pointer;
}
.option-box .option-box-header > div {
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
}
.option-box.open .option-box-header .option-box-toggle svg {
  rotate: 180deg;
}
.option-box .option-box-header .option-box-toggle svg {
  rotate: 0;
}
.option-box .option-box-body {
  display: none;
  border-top: 1px solid var(--outline-color);
}
.option-box.open .option-box-body {
  display: grid;
}
.option-box .option-box-body > div {
  display: grid;
  margin: 12px;
  gap: 8px;
}

.option-box .option-box-body .option-box-row {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
}
.option-box .option-box-body .option-box-row svg {
  vertical-align: middle;
}
.option-box .option-box-body .option-box-row label {
  font-size: 14px;
  font-weight: 600;
  color: var(--base-font-color);
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.25s ease;
}
.option-box .option-box-body .option-box-row.selected label {
  color: var(--primary-blue);
  opacity: 1;
}

.option-box .option-box-body .option-box-row:hover label {
  opacity: 1;
}
