.loading-indicator {
  display: grid;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--base-font-color);
  opacity: 0.5;
  transition: opacity 1s ease;
}
.loading-indicator.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.loading-indicator svg {
  width: 32px;
  height: 32px;
  display: block;
  margin: 32px;
}
