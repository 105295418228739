.page-toolbar {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  min-height: 40px;
}

.page-toolbar h2 {
  font-size: 24px;
  font-weight: 600;
}

.page-toolbar .page-toolbar-subheading {
  font-size: 14px;
  font-weight: 500;
  color: var(--light-font-color);
}

.page-toolbar .page-toolbar-actions {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, auto);
}

.page-toolbar .internal-badge {
  border: 1px solid var(--base-font-color);
  color: var(--base-font-color);
  border-radius: 6px;
  padding: 4px 8px;
  display: inline-grid;
  font-size: 10px;
  text-transform: uppercase;
  vertical-align: middle;
  margin-left: 16px;
}
