.flights {
  display: grid;
  height: 100%;
  overflow: hidden;
  grid-template-rows: auto 1fr;
  gap: 16px;
}

.flights .flights-container {
  display: grid;
  height: 100%;
  margin: 0 16px;
  overflow-y: hidden;
  gap: 8px;
}

.flights .flights-header {
  display: grid;
  gap: 16px;
  margin: 24px 24px 0 24px;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.flights .flights-header .actions {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
}
.flights .list-container {
  margin-bottom: 16px;
}
.flights .list-row > div {
  margin: 8px;
}
.flights .list-row > div:first-child {
  margin-left: 16px;
}

.flights .list-row .list-actions {
  grid-template-columns: 1fr;
}
.flights .list-row .flight-row-actions {
  justify-content: center;
  display: grid;
}
.flights .list-row .flight-actions {
  display: inline-grid;
}

.flight-edit-modal .modal-header span {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
}
.flight-edit-modal .action-buttons {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr auto;
}
.flight-edit-modal.create-flight .action-buttons {
  grid-template-columns: 1fr;
}
.flight-edit-modal .flight-edit-modal-rows {
  display: grid;
  gap: 16px;
  margin: 0 16px;
  align-content: start;
}
.flight-edit-modal .flight-edit-modal-row {
  display: grid;
  color: var(--base-font-color);
}
.flight-edit-modal .time-configuration {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto auto;
  align-items: center;
}
.flight-edit-modal .time-configuration.allow-clear {
  grid-template-columns: 1fr auto auto auto;
}
.flight-edit-modal .time-configuration-time-value {
  display: grid;
}

.flight-edit-modal .time-configuration-time-value .time-configuration {
  display: grid;
}
.flight-edit-modal .time-configuration-time-value .time-input-container {
  display: grid;
  border: 1px solid var(--outline-color);
  border-radius: 8px;
}
.flight-edit-modal .time-configuration-time-value .time-input-container > div {
  display: grid;
  margin: 4px 8px;
}
.flight-edit-modal .time-configuration-time-value .time-input select {
  font-size: 14px;
  font-weight: 600;
}
.flight-edit-modal .time-configuration-date-value {
  display: grid;
}

.flight-edit-modal .flightnumber-configuration {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.flight-edit-modal .flightnumber-configuration input {
  width: 142px;
  font-size: 14px;
  font-weight: 600;
}

.flight-edit-modal .registration-configuration {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.flight-edit-modal.create-flight .registration-configuration {
  grid-template-columns: auto 1fr auto;
}
.flight-edit-modal .registration-configuration .registration-details {
  display: grid;
  font-size: 12px;
  font-weight: 500;
}

.flight-edit-modal .registration-configuration select {
  width: 120px;
}

.flight-edit-modal .airline-configuration {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.flight-edit-modal .airport-configuration {
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr auto;
  align-items: center;
}

.flight-edit-modal .airport-configuration .airport-details {
  display: grid;
  font-size: 12px;
  font-weight: 500;
}
.flight-edit-modal .airport-configuration .airport-details > div:last-child {
  color: var(--light-font-color);
}

.flight-edit-modal.create-flight label {
  color: var(--base-font-color);
  font-weight: 600;
}
.flight-edit-modal.create-flight .reg-selector,
.flight-edit-modal.create-flight .search-selector {
  width: 140px;
}

.flight-edit-modal .clear-input {
  cursor: pointer;
  opacity: 0.75;
  transition: all 0.25s ease-out;
}

.flight-edit-modal .clear-input:hover {
  opacity: 1;
}
