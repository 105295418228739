/** Overrides for the MUI datepicker **/
/** Todo: Find a better way to style components **/
.MuiPickersLayout-root {
  background-color: transparent !important;
}
.MuiPickersPopper-root .MuiPaper-root {
  background-color: transparent !important;
  margin-top: 8px;
}

.MuiPickersDay-root:hover {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.MuiDateCalendar-root {
  font-family: "Inter" !important;
  font-weight: 600;
  color: var(--base-font-color);
  background-color: var(--darker-bg-color);
  border: 1px solid var(--outline-medium-color);
  border-radius: 8px;
}

.MuiDateCalendar-root button.Mui-disabled,
.MuiDateCalendar-root button,
.MuiDateCalendar-root .MuiPickersCalendarHeader-label,
.MuiDateCalendar-root .MuiDayCalendar-weekDayLabel,
.MuiDateCalendar-root .MuiButtonBase-root {
  font-family: "Inter" !important;
  font-weight: 600;
  color: var(--base-font-color);
  line-height: normal;
  font-size: 14px;
}
.MuiDateCalendar-root button.Mui-disabled {
  color: var(--light-font-color) !important;
}

.MuiButton-root.Mui-disabled {
  color: var(--light-font-color) !important;
  cursor: not-allowed !important;
  pointer-events: inherit !important;
}

.MuiDateCalendar-root .MuiYearCalendar-root {
  scrollbar-color: var(--scrollbars);
}
.MuiDateCalendar-root .Mui-selected {
  background-color: var(--primary-blue);
}
.MuiDateCalendar-root .MuiPickersDay-today {
  border: 1px solid var(--outline-medium-color) !important;
}

.MuiPickersCalendarHeader-label {
  text-transform: capitalize;
}

.daterange-picker {
  display: grid;
  gap: 4px;
  align-items: center;
  position: relative;
}

.daterange-picker .MuiDateCalendar-root {
  border: none;
}

.daterange-picker button {
  transition-property: background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  padding: 10px;
  color: var(--base-font-color);
  text-wrap: nowrap;
}
.daterange-picker button svg {
  margin-right: 8px;
}

.daterange-picker .daterange-picker-selectors {
  position: absolute;
  top: 48px;
  right: 0;
  z-index: 3;
  background-color: var(--darkest-bg-color);
  border: 1px solid var(--outline-medium-color);
  border-radius: 8px;
}
.daterange-picker .daterange-picker-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 16px;
  align-items: center;
  margin: 16px;
}
.daterange-picker .daterange-picker-container .presets {
  color: var(--base-font-color);
  height: 100%;
}

.daterange-picker .daterange-picker-container .presets > div {
  display: grid;
  grid-template-rows: repeat(2, auto) 1fr;
}
.daterange-picker .daterange-picker-container .presets .preset-choice {
  font-size: 14px;
  font-weight: 600;
  text-wrap: nowrap;
  padding: 8px;
}
.daterange-picker .daterange-picker-container .presets .preset-choice:hover {
  background-color: var(--medium-bg-color);
  border-radius: 8px;
}

.daterange-picker button.date-picker-active {
  background-color: var(--light-bg-color);
  border: 1px solid var(--outline-light-color);
  color: var(--base-font-color);
}

.daterange-picker button.date-picker {
  background-color: transparent;
  border: 1px solid var(--outline-medium-color);
  color: var(--base-font-color);
}

.daterange-picker button.date-picker:hover {
  background-color: var(--darker-bg-color);
}
