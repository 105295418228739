.map {
  display: grid;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.map .map-container {
  display: grid;
  height: 100%;
  margin: 0;
  overflow: hidden;
  grid-template-rows: auto 1fr;
}

.map .panels-container {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  overflow: hidden;
}

.map .panels-container {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  overflow: hidden;
}

.map .panels-container.open {
  grid-template-columns: 0px 1fr;
  gap: 8px;
  margin-left: 16px;
}
.map .panels-container.open.slide-open {
  grid-template-columns: 320px 1fr;
  transition: grid-template-columns 0.25s ease-out;
}
.map .panels-container.open.slide-close {
  grid-template-columns: 0px 1fr;
  transition: grid-template-columns 0.25s ease-out;
}

.map .panels-container.open.open-details {
  grid-template-columns: 320px 300px 1fr;
  gap: 8px;
}

.map .map-panel {
  display: grid;
  position: relative;
  height: 100%;
}

.map .map-panel .right-panel {
  display: grid;
  position: absolute;
  width: 280px;
  height: 100%;
  z-index: 4;
  top: 0;
  right: 0;
  overflow-y: auto;
  scrollbar-color: var(--scrollbars-transparent);
  grid-template-rows: auto 1fr;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.45)
  );
  backdrop-filter: blur(2px);
}
.map .map-panel .right-panel-content {
  display: grid;
}
.map .map-panel .right-panel-content .right-panel-content-header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.map .map-panel .right-panel-content > div {
  display: grid;
  margin: 16px;
  gap: 16px;
}
.map .map-panel .right-panel-content .right-panel-content-container {
  display: grid;
}
.map .map-panel .right-panel-content .close-btn {
  margin: 16px 0;
}

.map .map-panel .right-panel.full-module {
  width: 340px;
  grid-template-rows: 1fr;
}
.map .map-panel .right-panel.full-module .right-panel-content {
  height: 100%;
  overflow: hidden;
}
.map .map-panel .right-panel.full-module .right-panel-content > div {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.map .map-panel .overlay-panel {
  display: grid;
  position: absolute;
  height: 100%;
  z-index: 2;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.map .map-panel .overlay-panel-container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow-y: hidden;
}

.map .map-panel .overlay-panel-content {
  display: grid;
  gap: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  scrollbar-color: var(--scrollbars-transparent);
  scrollbar-width: none;
}

.map .map-panel .overlay-panel-container.dual {
  grid-template-columns: 335px 335px;
}

.map .map-panel .overlay-panel-tabs {
  display: grid;
  border: 1px solid var(--outline-color);
  background-color: var(--darker-bg-color);
  border-radius: 8px;
  margin-top: 16px;
  min-width: 320px;
}
.map .map-panel .overlay-panel-tabs-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  margin: 3px;
}
.map .map-panel .overlay-panel-tab {
  display: grid;
  border-radius: 6px;
  text-align: center;
  transition: all 0.25s ease-out;
  color: var(--light-font-color);
  cursor: pointer;
}

.map .map-panel .overlay-panel-tab:hover,
.map .map-panel .overlay-panel-tab.selected {
  opacity: 1;
  background-color: var(--medium-bg-color);
  color: var(--base-font-color);
}

.map .map-panel .overlay-panel-tab.critical:hover,
.map .map-panel .overlay-panel-tab.critical.selected {
  opacity: 1;
  background-color: var(--primary-red);
  color: var(--base-font-color);
}
.map .map-panel .overlay-panel-tab > div {
  margin: 6px;
  font-size: 14px;
  font-weight: 600;
}

.map .mapbox-container {
  height: 100%;
  filter: brightness(0.5);
  transition: all 0.25s ease;
}
.map .mapbox-container.map-loaded {
  filter: brightness(1);
}

.map .map-toolbar .map-toolbar-container {
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  margin: 16px;
  gap: 32px;
}

.map .mapbox-container .marker {
  display: grid;
  align-items: center;
  justify-items: center;
}
/* Override to MapBox style which prevents "flickering" **/
.map .mapbox-container .mapboxgl-marker {
  transition: none;
  opacity: 1;
}
.map .mapbox-container .marker:hover .marker-label {
  background-color: rgba(215, 215, 215, 1);
}
.map .mapbox-container .marker img {
  vertical-align: middle;
}
.map .mapbox-container .marker .marker-label {
  color: var(--darker-font-color);
  background-color: rgba(215, 215, 215, 0.9);
  border-radius: 4px;
  overflow: hidden;
  font-weight: 600;

  filter: drop-shadow(2px 2px 2px var(--drop-shadow-light-color));
}

.map .mapbox-container .marker .marker-label.split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.map .mapbox-container .marker .marker-label.split > div {
  margin: 0;
}
.map .mapbox-container .marker .marker-label.split > div > div {
  margin: 4px 6px;
}
.map .mapbox-container .marker .marker-label.split > div:first-child {
  color: var(--base-font-color);
  background-color: rgba(0, 0, 0, 0.9);
  text-transform: uppercase;
}
.map .mapbox-container .marker .marker-label.split.status-400 {
  border: 2px solid var(--primary-red);
}
.map .mapbox-container .marker .marker-label.split.status-201 {
  border: 1px solid var(--primary-yellow);
}
.map .mapbox-container .marker .marker-label.split.status-400 img.status-icon,
.map .mapbox-container .marker .marker-label.split.status-201 img.status-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.map .mapbox-container .marker .marker-label > div {
  display: grid;
  margin: 4px;
  gap: 2px;
}

.map .mapbox-container .marker span {
  line-height: normal;
  display: block;
  text-align: center;
  font-size: 10px;
  text-wrap: nowrap;
}

.map .mapbox-container .marker span.marker-label-status {
  display: inline-block;
}
.map .mapbox-container .marker span.marker-label-status.status-2 {
  color: var(--primary-red);
}
.map .mapbox-container .marker span.marker-label-status.status-3 {
  color: var(--primary-yellow);
}
.map .mapbox-container .marker span.marker-label-status.status-4 {
  color: var(--primary-blue);
}
.map .mapbox-container .marker span.marker-label-status.status-6 {
  color: var(--primary-green);
}

.map .mapbox-container .marker .marker-label-type {
  font-weight: 500;
  text-transform: uppercase;
}

.map .mapbox-container .marker .battery-status {
  display: inline-grid;
  border: 1px solid var(--outline-light-color);
  border-radius: 4px;
  margin: 4px 0 2px 0;
}
.map .mapbox-container .marker .battery-status > div {
  margin: 1px 2px 1px 1px;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  align-items: center;
}
.map .mapbox-container .marker .battery-status img {
  margin-top: -1px;
  width: 16px;
}

.map .mapbox-container .marker-airport {
  display: grid;
  width: 32px;
  height: 32px;
  color: var(--base-font-color);
  align-items: center;
  text-align: center;
  border-radius: 18px;
  cursor: pointer;
  visibility: hidden;
  background-color: rgba(23, 121, 218, 0.5);
}

.map .mapbox-container.map-show-airport .marker-airport {
  visibility: visible;
}
.map .mapbox-container .marker-airport > div {
  display: grid;
  align-items: center;
  text-align: center;
  font-size: 9px;
  font-weight: 600;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(23, 121, 218, 0.85);
  margin: auto;
}
.map .mapbox-container .marker-airport-stand {
  display: grid;
  color: var(--darker-font-color);
  align-items: center;
  text-align: center;
  border-radius: 2px;
  background-color: rgba(23, 121, 218, 0.15);
  border: 1px solid rgba(23, 121, 218, 0.5);
  z-index: 2;
  visibility: hidden;
}
.map .mapbox-container.map-show-stands .marker-airport-stand {
  visibility: visible;
}
.map .mapbox-container .marker-airport-stand > div {
  display: grid;
  align-items: center;
  text-align: center;
  font-size: 6px;
  font-weight: 500;
  margin: 1px;
}

.map .mapbox-container .marker-pin {
  display: grid;
  align-items: center;
  border-radius: 50%;
  background-color: var(--primary-red);
  z-index: 2;
  width: 8px;
  height: 8px;
}

.map .mapbox-container .marker-cluster {
  width: 32px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--base-font-color);
  display: grid;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 18px;
  cursor: pointer;
}

.map .mapbox-container .marker-cluster.medium {
  width: 48px;
  height: 48px;
  font-size: 16px;
  border-radius: 24px;
}
.map .mapbox-container .marker-cluster.large {
  width: 64px;
  height: 64px;
  font-size: 18px;
  border-radius: 32px;
}
.map .mapbox-container .marker-geofence {
  border-radius: 50%;
  background-color: rgba(23, 121, 218, 0.1);
}
.map .mapbox-container .marker-geofence.aircraft {
  border-radius: 50%;
  background-color: rgba(208, 47, 68, 0.5);
}

.map .mapboxgl-map {
  font: 14px Inter;
}
.map .mapboxgl-ctrl-attrib,
.map .mapboxgl-ctrl-bottom-left {
  display: none;
}
.map .marker-label-anchor {
  width: 0px;
  height: 0px;
  overflow: visible;
}
.map .marker-label-anchor {
  width: 0px;
  height: 0px;
  overflow: visible;
  display: none;
}

.map .aircraft-icon {
  display: block;
  filter: drop-shadow(2px 2px 4px var(--drop-shadow-color));
}

/** Label visibility and hover behavior **/
.map .hoverable {
  cursor: pointer;
}
.map .marker-label-anchor .marker-label {
  cursor: pointer;
  transition: all 0.25s ease;
}

.map.display-labels .marker-label-anchor {
  display: block;
  z-index: 2;
}
.map .marker-image-anchor[data-asset-type="aircraft"] {
  z-index: 2;
}

.map .marker-image-anchor[data-asset-type="user"],
.map .marker-image-anchor[data-asset-type="user"] {
  z-index: 3;
}

.map .marker-image-anchor[data-asset-type="vehicle"],
.map .marker-image-anchor[data-asset-type="vehicle"] {
  z-index: 4;
}

.map .marker-image-anchor[data-asset-type="vehicle"].hovered,
.map .marker-image-anchor[data-asset-type="user"].hovered,
.map .marker-image-anchor[data-asset-type="vehicle"].selected,
.map .marker-image-anchor[data-asset-type="user"].selected,
.map .marker-label-anchor.hovered,
.map .marker-label-anchor.selected,
.map .marker-label-anchor.selected.hovered {
  display: block;
  z-index: 5;
}

/** Gate change **/
.gate-change-panel .panel-field-row {
  display: grid;
  gap: 8px;
}

.gate-change-panel .search-field.normal .search-field-normal {
  display: grid;
}

.gate-change-panel .search-field.normal .search-field-search {
  display: none;
}

.gate-change-panel .search-field.search .search-field-normal {
  display: none;
}

.gate-change-panel .search-field.search .search-field-search {
  display: grid;
}

.gate-change-panel .gate-options {
  display: grid;
  max-height: 150px;
  overflow-y: auto;
  background-color: var(--dark-bg-color);
  color: var(--light-font-color);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: 1px solid var(--outline-color);
  border-right: 1px solid var(--outline-color);
  border-bottom: 1px solid var(--outline-color);
}
.gate-change-panel .new-stand {
  cursor: pointer;
}
.gate-change-panel .gate-option {
  display: grid;
  border-top: 1px solid var(--outline-color);
}
.gate-change-panel .gate-option:first-child {
  border-top: none;
}

.gate-change-panel .gate-option:hover {
  background-color: var(--medium-bg-color);
  color: var(--base-font-color);
  transition: background-color 0.25s ease;
  cursor: pointer;
}

.gate-change-panel .gate-option > div {
  font-size: 11px;
  font-weight: 600;
  margin: 12px 8px;
}

.gate-change-panel .panel-module-actions {
  display: grid;
  gap: 16px;
}

.gate-change-panel .search-input-box {
  position: relative;
}
.gate-change-panel .search-input-box input {
  padding: 10px;
  outline: none;
}
.gate-change-panel .search-input-box.show-options input {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.gate-change-panel .search-input-box svg {
  position: absolute;
  right: 12px;
  width: 12px;
  top: 9px;
}

.gate-change-panel .panel-module-header .panel-module-header-box {
  display: grid;
  grid-template-columns: 1fr auto;
}

.gate-change-panel .panel-module-body .panel-module-body-box {
  display: grid;
  gap: 24px;
  margin: 16px;
}

.gate-change-panel label {
  color: var(--base-font-color);
}

.gate-change-panel h3 {
  color: var(--base-font-color);
  font-size: 30px;
  font-weight: 600;
}

.gate-change-panel .turnaround-general {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}
.gate-change-panel .turnaround-general .stat {
  display: grid;
  border: 1px solid var(--outline-color);
  border-radius: 8px;
  gap: 4px;
  cursor: default;
}
.gate-change-panel .turnaround-general span {
  font-size: 12px;
  font-weight: 600;
  margin: 0 8px 8px 8px;
}
.gate-change-panel .turnaround-general label {
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 8px 8px 0 8px;
}

/* Overlay Toggle */
.map .overlay-toggle {
  display: inline-grid;
  border-radius: 8px;
  border: 1px solid var(--outline-color);
  width: 270px;
}

.map .overlay-toggle-box {
  margin: 3px;
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 4px;
}
.map .overlay-toggle .toggle-option {
  display: grid;
  align-items: center;
  border-radius: 6px;
}
.map .overlay-toggle .toggle-option:hover {
  background-color: var(--darker-bg-color);
  transition: background-color 0.25s ease;
}
.map .overlay-toggle .toggle-option.selected {
  background-color: var(--medium-bg-color);
}
.map .overlay-toggle .toggle-option span {
  color: var(--light-font-color);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin: 8px 16px;
}
.map .overlay-toggle .toggle-option:hover span,
.map .overlay-toggle .toggle-option.selected span {
  color: var(--base-font-color);
}

/* Overlay Module */
.map .overlay-module {
  background-color: var(--dark-bg-color);
  border-radius: 8px;
  border: 2px solid var(--outline-color);
  cursor: default;
  /* min-width: 320px; */
  width: 380px;
}
.map .overlay-module.disabled {
  cursor: not-allowed;
}
.map .overlay-module:first-child,
.map .overlay-section-label:first-child {
  margin-top: 16px;
}
.map .overlay-module:last-child {
  margin-bottom: 16px;
}
.map .overlay-module:not(.selected):not(.disabled):hover {
  cursor: pointer;
}
.map .overlay-module.selected,
.map .overlay-module:hover,
.map .overlay-module.hovered {
  background: linear-gradient(0turn, #324364, #161c27);
}

.map .overlay-module.status-critical,
.map .overlay-module.status-400 {
  border: 2px solid var(--primary-red);
}
.map .overlay-module.status-201 {
  border: 1px solid var(--primary-yellow);
}

.map .overlay-section-label {
  margin: 0 16px;
  font-size: 18px;
  font-weight: 600;
}

.map .overlay-module-box {
  display: grid;
  gap: 16px;
  margin: 16px;
}

.map .overlay-module-box .turnaround-resources-info {
  display: grid;
  border: 1px solid var(--outline-color);
  border-radius: 8px;
}
.map .overlay-module-box .turnaround-resources-info > div {
  display: grid;
  grid-template-columns: 1fr auto auto;
  font-size: 10px;
  font-weight: 500;
  gap: 8px;
  margin: 8px;
  color: var(--light-font-color);
  text-transform: uppercase;
  align-items: center;
}
.map .overlay-module-box .turnaround-resources-info .resource-count {
  font-size: 12px;
  font-weight: 600;
  color: var(--base-font-color);
}
.map .overlay-module-box .turnaround-resources-info .resource-count span {
  font-size: 10px;
  font-weight: 500;
  color: var(--light-font-color);
  margin-left: 4px;
}

.map .overlay-module-box .flight-info,
.map .overlay-module-box .turnaround-flight-info {
  display: grid;
  grid-template-columns: minmax(0, 24px) repeat(3, 1fr);
  align-items: start;
  gap: 8px;
}
.map .overlay-module-box .flight-info > div:first-child,
.map .overlay-module-box .turnaround-flight-info > div:first-child {
  display: grid;
  align-content: center;
  height: 100%;
}
.map .overlay-module-box .turnaround-additional-notes {
  display: grid;
  gap: 8px;
}
.map .overlay-module-box .turnaround-remarks {
  display: grid;
  border: 1px solid var(--outline-color);
}
.map .overlay-module-box .turnaround-remarks > div {
  display: grid;
  margin: 8px;
  font-size: 12px;
  font-weight: 500;
  color: var(--base-font-color);
}

.map .overlay-module-box svg {
  width: 20px;
  height: 20px;
}
.map .overlay-module-box .turnaround-info {
  display: grid;
  grid-template-columns: minmax(0, 24px) 1fr;
  align-items: center;
  gap: 8px;
}

.map .overlay-module-box label {
  font-size: 8px;
  text-transform: uppercase;
}
.map .overlay-module-box .value-pair {
  display: grid;
  grid-template-rows: auto 1fr;
}
.map .overlay-module-box .value {
  font-size: 12px;
  font-weight: 600;
}

.map .overlay-module-box .value-pair.large-value {
  grid-template-rows: auto 1fr;
}

.map .overlay-module-box .value-pair.large-value .value {
  font-size: 16px;
  font-weight: 700;
}

.map .overlay-module-actions {
  display: grid;
}
.map .overlay-module-actions button {
  padding: 8px 10px;
}

/* Filter button */
.map .filter-button {
  display: inline-grid;
}
.map .filter-button-container {
  position: relative;
}
.map .filter-button-actions {
  display: grid;
  grid-template-columns: 1fr;
}
.map .filter-button-actions.secondary {
  grid-template-columns: auto auto;
  gap: 16px;
}
.map .filter-button-menu {
  margin-top: 4px;
  position: absolute;
  z-index: 3;
  left: 0;
}
.map .filter-button button svg {
  margin-right: 4px;
}
.map .filter-menu {
  display: grid;
  background-color: var(--dark-bg-color);
  border-radius: 8px;
  border: 1px solid var(--outline-color);
}
.map .filter-menu-content {
  display: grid;
  margin: 8px 16px;
  gap: 8px;
}
.map .filter-menu-option-box {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
}
.map .filter-menu-option-box label {
  text-wrap: nowrap;
}
.map .filter-menu-option:hover label,
.map .filter-menu-option.selected label {
  color: var(--base-font-color);
  cursor: pointer;
}

.map .map-recenter {
  position: absolute;
  bottom: 18px;
  z-index: 3;
  right: 18px;
  transform: translate(-50%, 0);
}
.map .map-recenter .map-recenter-menu-container {
  position: relative;
}
.map .map-recenter button svg {
  margin-left: 4px;
}

.map .map-recenter button.active svg {
  transform: rotate(180deg);
}

.map .map-recenter .map-recenter-menu {
  display: grid;
  position: absolute;
  right: 0;
  bottom: 48px;
  width: 240px;
  border: 1px solid var(--outline-color);
  background-color: var(--dark-bg-color);
  border-radius: 8px;
  cursor: default;
}
.map .map-recenter .map-recenter-menu-item {
  font-size: 14px;
  font-weight: 600;
  opacity: 0.7;
  border-radius: 6px;
}
.map .map-recenter .map-recenter-menu-item > div {
  margin: 8px;
}
.map .map-recenter .map-recenter-menu-item:hover {
  opacity: 1;
  cursor: pointer;
  background-color: var(--intermediate-bg-color);
}
.map .map-recenter .map-recenter-menu-item span {
  font-size: 10px;
  font-weight: 500;
  color: var(--light-font-color);
}
.map .map-recenter .map-recenter-menu > div {
  display: grid;
  margin: 16px 8px;
}

.map .map-recenter button {
  background-color: var(--darker-bg-color);
}

/** MapInfo **/
.map .map-info {
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  right: 16px;
  top: 16px;
}
.map .map-info > div {
  margin: 8px;
  display: grid;
  grid-template-columns: 1fr auto;
  font-size: 10px;
  gap: 4px;
}

.map .crew-member-info {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 16px;
}
.map .crew-member-info-details {
  font-size: 10px;
  display: grid;
  gap: 2px;
}

.map .vehicle-info {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 16px;
}

.map .vehicle-status {
  border: 1px solid var(--pure-white);
  border-radius: 1rem;
  width: 16px;
  height: 16px;
  display: grid;
}

.map .vehicle-info .status-indicator {
  border: 3px solid var(--outline-medium-color);
  border-radius: 1rem;
  background-color: var(--primary-green);
  width: 10px;
  height: 10px;
}

/* Status should refer to the map status of vehicle */
.map .vehicle-info .status-indicator.status-0,
.map .vehicle-info .status-indicator.status-1 {
  border-radius: 1rem;
  background-color: var(--primary-off-white);
}
.map .vehicle-info .status-indicator.status-2 {
  border-radius: 1rem;
  background-color: var(--primary-red);
}
.map .vehicle-info .status-indicator.status-3 {
  border-radius: 1rem;
  background-color: var(--primary-yellow);
}
.map .vehicle-info .status-indicator.status-4 {
  border-radius: 1rem;
  background-color: var(--primary-blue);
}
.map .vehicle-info .status-indicator.status-5 {
  border-radius: 1rem;
  background-color: var(--primary-yellow);
}
.map .vehicle-info .status-indicator.status-6 {
  border-radius: 1rem;
  background-color: var(--primary-off-white);
}

.map .vehicle-info .vehicle-details {
  display: grid;
  color: var(--light-font-color);
  font-size: 12px;
  font-weight: 500;
  gap: 6px;
  max-width: 200px;
}
.map .vehicle-info .vehicle-name {
  display: grid;
  grid-template-columns: auto 55px 1fr;
  align-items: center;
  gap: 4px;
  color: var(--base-font-color);
  font-size: 14px;
  font-weight: 600;
}
.map .vehicle-info .vehicle-name > div {
  position: relative;
}
.map .vehicle-info .status-badge > div {
  margin: 2px 6px;
  color: var(--darker-font-color);
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
}
.map .vehicle-info .vehicle-status-details {
  display: grid;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 500;
  gap: 4px;
}

.map .vehicle-info .battery-power {
  font-size: 14px;
  font-weight: 500;
  transform: scale(0.6);
  position: absolute;
  top: -14px;
  left: -6px;
}

/** Overrides to make turnaround context panels overlay on map */
.map .context-panel {
  overflow: visible;
  margin: 16px 0;
}

.map .context-panel.transparent {
  background-color: transparent;
}
.map .context-panel.transparent .stat {
  background-color: var(--intermediate-bg-color);
}

.map .map-panel .overlay-panel-content:first-child {
  margin-left: 16px;
}

.map .map-panel .turnaround-panel {
  width: 340px;
}

.pulseLoop {
  animation-duration: 2s;
  animation-name: pulseLoopAnimation;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes pulseLoopAnimation {
  from {
    background-color: rgba(23, 121, 218, 0.1);
  }
  50% {
    background-color: rgba(23, 121, 218, 0.65);
  }
  to {
    background-color: rgba(23, 121, 218, 0.1);
  }
}

/** Pin modal **/
.modal.pin-marker-modal {
  height: 300px;
}
.modal.pin-marker-modal .field-pairs {
  display: grid;
  margin: 0 16px;
  align-content: start;
  gap: 8px;
}
.modal.pin-marker-modal .field-pair {
  display: grid;
  gap: 8px;
}
.modal.pin-marker-modal .field-input {
  display: grid;
  gap: 8px;
}
.modal.pin-marker-modal .field-input a {
  text-align: right;
}
