.leaderboard {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  overflow: hidden;
}
.leaderboard .page-toolbar {
  align-items: center;
}
.leaderboard .leaderboard-toolbar {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 32px;
  align-items: center;
}
.leaderboard .metrics-filters {
  display: grid;
  margin: 0 16px;
  align-items: center;
  gap: 8px;
}
.leaderboard .leaderboard-toolbar h2 {
  font-size: 24px;
  text-wrap: nowrap;
}

.leaderboard .leaderboard-content {
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
}
.leaderboard .leaderboard-container {
  display: grid;
  grid-template-rows: auto 1fr;
  margin: 0 0 0 16px;
  gap: 16px;
  overflow: hidden;
}
.leaderboard .chart-module {
  display: grid;
  border-radius: 12px;
  background-color: var(--dark-bg-color);
  margin-right: 16px;
  margin-bottom: 24px;
}
.leaderboard .chart-module h4 {
  font-size: 20px;
  font-weight: 600;
}

.leaderboard .chart-module .chart-container {
  display: grid;
  margin: 24px;
  gap: 16px;
  grid-template-rows: auto 1fr;
}

.leaderboard .chart-module .chart-component {
  display: grid;
  border-radius: 12px;
  background-color: var(--darker-bg-color);
}
.leaderboard .chart-title-bar {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.leaderboard .chart-title-bar > div {
  display: grid;
}
.leaderboard .chart-title-bar .button-switch {
  width: 128px;
}
.leaderboard .chart-title-bar .button-switch-box {
  grid-template-columns: 1fr 1fr;
}
