.battery-power {
  display: inline-grid;
  background-color: #232a37;
  border-radius: 10px;
  margin: 0 auto 0 0;
}
.battery-power .battery-power-box {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 4px;
  margin: 2px 8px;
}

.battery-power .battery-power-icon {
  position: relative;
  overflow: hidden;
  width: 24px;
  height: 24px;
}
.battery-power .battery-power-icon svg {
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 1;
}

.battery-power .battery-power-fill {
  position: absolute;
  background-color: #b0b7c3;
  height: 10px;
  top: 7px;
  left: 2px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.battery-power .battery-power-icon.danger .battery-power-fill {
  background-color: var(--primary-red);
}
