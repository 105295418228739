.confirmation-message {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
}

.confirmation-message button {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
}
.confirmation-message button.Mui-disabled {
  color: inherit;
}
.confirmation-message span {
  font-family: Inter;
  color: var(--light-font-color);
  font-size: 14px;
  font-weight: 500;
}

.confirmation-message .confirmation-detail-rows {
  display: inline-grid;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
}
.confirmation-message .confirmation-detail-row {
  display: flex;
  align-items: center;
}
.confirmation-message .confirmation-detail {
  font-size: 16px;
  font-weight: 600;
}

.confirmation-message .confirmation-content {
  color: var(--base-font-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}
