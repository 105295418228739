.login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  height: 100%;
}

.login .login-left,
.login .login-right {
  display: grid;
}

.login .login-splash-img {
  margin: 32px 0 32px 32px;
  background-image: url("/public/login_background.png");
  background-size: cover;
  background-position: center;
}

.login .login-container {
  display: grid;
  grid-template-rows: auto 1fr;
  margin: 32px 32px 32px 0;
}

.login .login-form {
  display: grid;
  gap: 16px;
  margin: auto;
  width: 400px;
}

.login .login-form-header {
  margin-bottom: 16px;
  font-size: 14px;
  display: grid;
  gap: 32px;
}

.login .login-body {
  display: grid;
  align-items: center;
  align-content: center;
  height: 100%;
}

.login .label-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 8px;
}
.login .label-container.no-cols {
  grid-template-columns: 1fr;
}
.login .label-container .label-container-right {
  text-align: right;
}
.login span.login-form-info {
  color: var(--light-font-color);
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: inline-grid;
  opacity: 0;
}
.login span.login-form-info.show {
  transition-delay: 1s;
  transition-property: opacity;
  transition-duration: 0.25s;
  opacity: 1;
}

.login .login-form-note {
  text-align: center;
  font-size: 14px;
  margin-top: 32px;
}

.login a {
  color: var(--primary-blue);
  text-decoration: underline;
}

.login .login-confirmation-code > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
  margin-bottom: 32px;
}
.login .login-confirmation-code input {
  width: 3em;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}

/* Form stuff */
.login button {
  background-color: var(--primary-blue);
  border: 0;
  border-radius: 8px;
  color: var(--base-font-color);
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  padding: 8px;
}

.login button:disabled {
  filter: opacity(0.5);
  cursor: not-allowed;
}

.password-input {
  position: relative;
}

.password-input .password-toggle {
  position: absolute;
  top: calc(50% - 12px);
  right: 8px;
  cursor: pointer;
}

.password-input .password-toggle svg {
  width: 24px;
  height: 24px;
}

.login .terms-checkbox {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
}

.login .terms-checkbox > label,
.login .terms-checkbox > div {
  margin: 16px 0;
}

.login .terms-checkbox a {
  text-decoration: underline;
}

/** password-requirments **/
.password-requirments {
  display: grid;
  border: 1px solid var(--outline-color);
  border-radius: 8px;
  margin: 16px;
}
.password-requirments > div {
  display: grid;
  margin: 12px;
  gap: 4px;
}
.password-requirments .password-requirment svg {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}
.password-requirments .password-requirment {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  gap: 8px;
  filter: grayscale();
  opacity: 0.5;
  transition: all 0.25s ease-out;
}
.password-requirments .password-requirment.completed {
  filter: none;
  opacity: 1;
}
