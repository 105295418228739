.settings {
  height: 100%;
  overflow: hidden;
}
.settings .settings-main {
  overflow: hidden;
  margin-bottom: 24px;
}
.settings .settings-container {
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 0 24px 0 32px;
  gap: 16px;
  height: 100%;
}
.settings .settings-sidebar {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
.settings .settings-sidebar > div {
  display: grid;
  gap: 16px;
}

.settings .settings-menu {
  display: grid;
  background-color: var(--dark-bg-color);
  min-width: 280px;
  border-radius: 12px;
}

.settings .settings-menu-header {
  margin-bottom: 8px;
}

.settings .settings-menu-header.two {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.settings .settings-menu-content {
  display: grid;
  margin: 24px;
  gap: 4px;
}

.settings .settings-menu-item {
  display: grid;
  grid-template-columns: 3px 1fr;
  gap: 8px;
  color: var(--light-font-color);
  font-size: 14px;
  font-weight: 500;
  cursor: default;
  transition: background-color 0.25s ease;
  border-radius: 3px;
  overflow: hidden;
}

.settings .settings-menu-item:hover {
  color: var(--base-font-color);
  background-color: var(--intermediate-bg-color);
  cursor: pointer;
}

.settings .settings-menu-item.selected {
  color: var(--base-font-color);
  background-color: var(--light-bg-color);
}
.settings .settings-menu-item.selected .indicator {
  background-color: var(--highlight-bg-color);
}

.settings .settings-menu-item .menu-label {
  display: grid;
  margin: 16px 8px;
}

.settings .copyright {
  color: var(--light-font-color);
  font-size: 12px;
  font-weight: 400;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 4px;
  margin: 16px 0;
}

.edit-profile {
  display: grid;
  background-color: var(--dark-bg-color);
  border-radius: 12px;
}
.edit-profile > div {
  margin: 24px;
}

.settings .settings-form-header {
  display: grid;
  gap: 8px;
  margin-bottom: 32px;
}
.settings .settings-form-header span {
  color: var(--light-font-color);
  font-size: 16px;
  font-weight: 400;
}

.settings .user-pic-row {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  margin-top: 8px;
  align-items: center;
}

.settings .settings-form {
  display: grid;
  gap: 16px;
}

.settings .settings-form .field-row > div {
  display: grid;
  gap: 8px;
}

.settings .settings-form .field-row.two {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.settings .settings-form .field-row.actions {
  margin-top: 16px;
}

.password-input {
  position: relative;
}

.password-input .password-toggle {
  position: absolute;
  top: calc(50% - 12px);
  right: 8px;
  cursor: pointer;
}

.password-input .password-toggle svg {
  width: 24px;
  height: 24px;
}

.settings .internal-badge {
  border: 1px solid var(--base-font-color);
  color: var(--base-font-color);
  border-radius: 6px;
  padding: 4px 8px;
  display: inline-grid;
  font-size: 10px;
  text-transform: uppercase;
  vertical-align: middle;
  margin-left: 16px;
}
.settings .profile-image-upload {
  display: grid;
  gap: 8px;
}

.map-style {
  display: grid;
  background-color: var(--dark-bg-color);
  border-radius: 12px;
}
.map-style > div {
  margin: 24px;
}

.map-style .map-style-header {
  display: grid;
  gap: 8px;
  margin-bottom: 32px;
}

.map-style .map-style-header span {
  color: var(--light-font-color);
  font-size: 16px;
  font-weight: 400;
}

.map-style .map-style-content {
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 16px;
}
.map-style .map-style-content-row {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
}

.notifications-settings .notifications-settings-actions,
.map-style .map-style-actions {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}

.map-style .big-button {
  display: grid;
  gap: 16px;
  width: 360px;
  border-radius: 12px;
  background-color: var(--darker-bg-color);
  border: 1px solid transparent;
  cursor: pointer;
  transition: all;
  transition-duration: 0.25s;
}
.map-style .big-button > div {
  display: grid;
  gap: 16px;
  margin: 12px 10px;
}
.map-style .big-button:hover {
  background-color: var(--intermediate-bg-color);
  border: 1px solid var(--outline-medium-color);
}
.map-style .big-button.selected {
  border: 1px solid var(--primary-blue);
  background-color: var(--darker-bg-color);
}
.map-style .big-button-image {
  height: 108px;
  border-radius: 12px;
  background-size: cover;
  background-position: center;
}
.map-style .big-button-detail {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
}

.notifications-settings {
  display: grid;
  background-color: var(--dark-bg-color);
  border-radius: 12px;
  height: 100%;
  min-width: 640px;
}
.notifications-settings > div {
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: hidden;
}

.notifications-settings .notifications-settings-header {
  display: grid;
  gap: 8px;
  margin: 24px 24px 16px 24px;
}
.notifications-settings .notifications-settings-content {
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 24px;
  margin-bottom: 16px;
}
.notifications-settings .notifications-settings-actions {
  display: grid;
  margin: 0 24px 24px 24px;
}
.notifications-settings .notifications-settings-content-row {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid var(--outline-medium-color);
  font-weight: 600;
  text-wrap: nowrap;
}
.notifications-settings .notifications-settings-content-row:last-child {
  border-bottom: 1px solid transparent;
}
.notifications-settings .notifications-settings-content-row > div {
  margin: 24px;
}
