.main-menu .menu-header {
  display: grid;
  align-content: center;
  height: 95px;
  margin: 0 16px;
}

.main-menu .menu-container {
  overflow-y: auto;
  scrollbar-color: var(--scrollbars);
  width: 240px;
}

.main-menu .menu-footer {
  text-align: center;
  background: linear-gradient(#377dff, #0c42a8);
  padding: 8px;
  color: var(--base-font-color);
  text-transform: uppercase;
}
.main-menu .menu-footer.prod {
  display: none;
}
.main-menu .app-status {
  cursor: default;
}
.main-menu .main-logo {
  display: grid;
}

.main-menu .menu-item {
  display: grid;
  grid-template-columns: 4px 1fr;
  cursor: pointer;
  gap: 18px;
  color: var(--medium-font-color);
  font-size: 14px;
  font-weight: 600;
  transition: all 0.25s ease;
}

.main-menu .menu-item.toggle {
  grid-template-columns: 4px 1fr auto;
}

.main-menu .menu-item .menu-item-indicator {
  height: 48px;
}

.main-menu .menu-item .menu-item-toggle svg {
  width: 18px;
  height: 18px;
  margin: 8px;
  transform: rotate(0deg);
}

.main-menu .menu-item.open .menu-item-toggle svg {
  transform: rotate(180deg);
}
.main-menu .menu-item-icon {
  width: 24px;
  height: 24px;
}
.main-menu .menu-item-icon svg {
  width: 24px;
  height: 24px;
}

.main-menu .menu-item .menu-item-body,
.main-menu .menu-item .menu-item-toggle {
  display: grid;
  height: 48px;
  align-content: center;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
}

.main-menu .menu-item .menu-item-body.indicators {
  grid-template-columns: auto 1fr auto;
  align-items: center;
}
.main-console.compact .main-menu .menu-item .menu-item-body.indicators {
  gap: 0;
}

.main-menu .menu-item.active .menu-item-indicator {
  background-color: var(--highlight-bg-color);
}
.main-menu .menu-item:hover {
  color: var(--base-font-color);
  background-color: var(--intermediate-bg-color);
}

.main-menu .menu-item.active {
  color: var(--base-font-color);
  background-color: var(--light-bg-color);
  transition: background-color 0.25s ease;
}

.main-menu .menu-item .status-indicator {
  display: grid;
  border-radius: 4px;
  background-color: var(--primary-blue);
  margin: 0 16px;
  font-size: 12px;
  font-weight: 600;
  color: var(--base-font-color);
}
.main-menu .menu-item .status-indicator > div {
  display: grid;
  margin: 2px 6px;
}
.main-console.compact .main-menu .menu-item .status-indicator {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 4px;
}
.main-console.compact .main-menu .menu-item .status-indicator > div {
  display: none;
}
/** Menu Handle **/
.menu-handle {
  display: grid;
  position: absolute;
  cursor: pointer;
  z-index: 100;
  top: 50%;
  transform: translate(0 -50%);
  background-color: var(--dark-bg-color);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  opacity: 0;
  width: 0;
  transition: all 0.25s ease-out;
}
.menu-handle.hovered,
.menu-handle:hover {
  opacity: 1;
  width: 12px;
}
.menu-handle > div {
  display: grid;
  transition: all 0.25s ease-out;
  opacity: 0;
}
.menu-handle:hover > div {
  background-color: var(--pure-white-semi);
  opacity: 1;
}
.menu-handle svg {
  vertical-align: middle;
  rotate: 90deg;
  margin: 18px 0;
  width: 12px;
}
.menu-handle.compact svg {
  rotate: 90deg;
}
.main-console.compact .menu-handle svg {
  rotate: -90deg;
}
