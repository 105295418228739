.tab-control {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin: 0 16px 8px 16px;
  gap: 1px;
}
.tab-control > div {
  display: grid;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.25s ease-out;
  color: var(--light-font-color);
  background-color: var(--gray-dimmed-color);
}

.tab-control > div:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.tab-control > div:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.tab-control > div span {
  margin: 8px;
}
.tab-control > div:not(.selected):hover {
  color: var(--base-font-color);
  /* background-color: var(--gray-light-color); */
}
.tab-control > div.selected {
  color: var(--base-font-color);
  background-color: var(--gray-light-color);
}
