.modal-container .alert-messages-rows {
  display: grid;
  margin: 0 24px;
  gap: 8px;
  align-content: start;
}
.modal-container .alert-messages-row {
  color: var(--base-font-color);
  font-size: 14px;
  font-weight: 500;
}
