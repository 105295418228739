.dashboard {
  display: grid;
}
.dashboard .dashboard-container {
  display: grid;
  margin: 16px 16px 16px 32px;
}

.dashboard .dashboard-widgets {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 32px;
  margin: 16px 0;
}

.dashboard .widget {
  border-radius: 12px;
  background-color: var(--dark-bg-color);
  padding: 24px;
}

/** Company Information **/
.dashboard .widget.company-info {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto 1fr;
  gap: 12px;
  background-position: center;
  background-size: cover;
}

.dashboard .widget.company-info span {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.75;
}
.dashboard .widget.company-info h3 {
  font-size: 28px;
  font-weight: 600;
  text-wrap: wrap;
}

/** Weather Conditions **/
.dashboard .widget.weather-info {
  background: radial-gradient(circle at 100%, #377dff, #0c42a8);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
}
.dashboard .widget.weather-info .conditions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}
.dashboard .widget.weather-info .conditions > div {
  margin: auto 8px;
}

.dashboard .widget.weather-info .conditions h3 {
  display: inline;
}
.dashboard .widget.weather-info .conditions svg {
  margin: auto 0;
  width: 38px;
  height: 38px;
}
.dashboard .widget.weather-info .conditions span {
  font-size: 14px;
  font-weight: 600;
}
.dashboard .widget.weather-info .conditions-detail {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
}

.dashboard .widget.weather-info .stat {
  display: grid;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 14px;
  gap: 4px;
  align-content: center;
}

.dashboard .widget.weather-info .stat span {
  font-size: 13px;
  font-weight: 600;
}

.dashboard .widget.weather-info .stat h3 {
  font-size: 20px;
  font-weight: 600;
  display: inline;
}

/** Vehicle Distribution **/
.dashboard .widget.vehicle-info {
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 16px;
}
.dashboard .widget.vehicle-info h2 {
  font-size: 20px;
  font-weight: 600;
}

.dashboard .widget.vehicle-info .stats {
  display: grid;
  gap: 16px;
}

.dashboard .widget.vehicle-info .stat {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
  padding: 16px;
  background-color: var(--medium-bg-color);
  border-radius: 15px;
}
.dashboard .widget.vehicle-info .stat.clickable {
  cursor: pointer;
  transition: all 0.25s ease-out;
}
.dashboard .widget.vehicle-info .stat.clickable:hover {
  background-color: var(--light-bg-color);
}

.dashboard .widget.vehicle-info .stat h3 {
  font-size: 36px;
  font-weight: 600;
}
.dashboard .widget.vehicle-info .stat span {
  font-size: 14px;
  font-weight: 500;
}
.dashboard .widget.vehicle-info .stat.online span {
  color: var(--base-font-color);
}
.dashboard .widget.vehicle-info .stat.offline span {
  color: var(--base-font-color);
}

/** Turnaround info **/
.dashboard .widget.turnaround-info .chart {
  display: grid;
}

.dashboard .widget .chart-container {
  display: grid;
  width: 100%;
}

.dashboard .widget.turnaround-info h2 {
  font-size: 20px;
  font-weight: 600;
}

/** Turnaround cards **/
.dashboard .widget.turnaround-cards {
  display: grid;
  grid-column: 1/3;
  grid-template-rows: auto 1fr;
  gap: 16px;
}
.dashboard .widget.turnaround-cards h2 {
  font-size: 20px;
  font-weight: 600;
}

.dashboard .widget.turnaround-cards .widget-body {
  display: grid;
  gap: 32px;
}

.dashboard .widget.turnaround-cards .turnaround-progress .progress-info {
  grid-template-columns: auto auto 1fr;
}

.dashboard .widget.turnaround-cards .turnaround-progress .operation-status {
  display: inline-flex;
}
