/** Override fixes for the Snackbar **/
.MuiSnackbar-root button.MuiIconButton-root {
  width: 30px;
  height: 30px;
}
.MuiSnackbar-root button.MuiIconButton-root svg {
  margin: 0;
}
.MuiSnackbar-root .MuiSnackbarContent-action {
  gap: 8px;
}
.MuiSnackbar-root .MuiSnackbarContent-action button.primary {
  padding: 8px 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  border-radius: 6px;
}
