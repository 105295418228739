.status-badge {
  display: inline-grid;
  border-radius: 4px;
  border: 1px solid transparent;
}
.status-badge.allcap {
  text-transform: uppercase;
}
.status-badge.nominal {
  background-color: var(--pure-white-semi);
}
.status-badge.outlined {
  color: var(--light-font-color);
  border: 1px solid var(--light-font-color);
}
.status-badge.critical {
  color: var(--primary-red);
  background-color: var(--primary-red-semi);
}
.status-badge.warning {
  color: var(--primary-yellow);
  background-color: var(--primary-yellow-semi);
}

.status-badge.generic {
  color: var(--darker-font-color);
  background-color: var(--primary-off-white);
}

.status-badge > div {
  display: grid;
  text-wrap: nowrap;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  vertical-align: middle;
  margin: 4px 6px;
  gap: 4px;
}
.status-badge.icons > div {
  grid-template-columns: auto 1fr;
}

.status-badge .status-badge-icon {
  display: grid;
}

.status-badges {
  display: inline-flex;
  gap: 4px 8px;
  flex-wrap: wrap;
}
