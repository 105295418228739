.checkbox-container svg {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.checkbox-container.disabled svg {
  cursor: not-allowed;
  width: 20px;
  height: 20px;
  opacity: 0.7;
}
.checkbox-container.disabled svg rect {
  fill: var(--disabled-bg-color);
}
