.turnarounds {
  height: 100%;
  overflow: hidden;
}

.turnarounds .turnarounds-container {
  display: grid;
  height: 100%;
  margin: 0 0 0 16px;
  overflow: hidden;
  grid-template-rows: auto 1fr;
}

.turnarounds .panels-container {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  overflow: hidden;
}

.turnarounds .panels-container.open {
  grid-template-columns: 0px 1fr;
  gap: 8px;
}
.turnarounds .panels-container.open.slide-open {
  grid-template-columns: 320px 1fr;
  transition: grid-template-columns 0.25s ease-out;
}
.turnarounds .panels-container.open.slide-close {
  grid-template-columns: 0px 1fr;
  transition: grid-template-columns 0.25s ease-out;
}

.turnarounds .panels-container.open.open-details {
  grid-template-columns: 320px 320px 1fr;
  gap: 8px;
}

.turnarounds .turnarounds-timeline-container {
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: var(--darker-bg-color);
  border: 1px solid var(--dark-bg-color);
  position: relative;
  overflow: auto;
}

.turnarounds .turnarounds-timeline-body {
  position: relative;
}
.turnarounds .turnarounds-timeline-content {
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  grid-template-rows: repeat(2, auto) 1fr;
  overflow-x: visible;
  overflow-y: visible;
}
.turnarounds .turnarounds-timeline-grid {
  height: 100%;
}
.turnarounds .turnarounds-timeline-header,
.turnarounds .turnarounds-timeline-grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(100px, 1fr));
}

.turnarounds .turnarounds-timeline-header > div,
.turnarounds .turnarounds-timeline-grid > div {
  text-align: center;
  align-items: center;
  display: grid;
  border-right: 1px solid var(--dark-bg-color);
  font-weight: 500;
  font-size: 14px;
  color: var(--input-font-color);
}

.turnarounds .turnarounds-timeline-header > div {
  border-bottom: 1px solid var(--dark-bg-color);
}
.turnarounds .turnarounds-timeline-header > div span {
  margin: 32px 0 32px -100%;
}
.turnarounds .turnaround-timeline-bar-container {
  margin: 16px 0 0 0;
  overflow: visible;
}
.turnarounds .turnaround-timeline-bar-container.selected {
  position: relative;
}
.turnarounds .turnaround-timeline-bar {
  display: grid;
  align-items: center;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 800;
  color: var(--primary-off-white);
  background-color: rgba(193, 199, 208, 0.25);
  text-overflow: ellipsis;
  text-wrap: nowrap;
  cursor: pointer;
}

.turnarounds .turnaround-timeline-bar span {
  margin: 6px 0 6px 8px;
  text-overflow: ellipsis;
}

.turnarounds .turnaround-timeline-delay,
.turnarounds .turnaround-timeline-operation-delay {
  background-color: rgba(208, 47, 68, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
}
.turnarounds .turnaround-timeline-operation-main svg {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-left: 4px;
}

.turnarounds .turnaround-timeline-bar.gtat {
  background-color: rgba(23, 121, 218, 0.25);
}

.turnarounds .turnaround-timeline-bar.gtat.in-progress {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(55, 125, 255, 0.25),
    rgba(55, 125, 255, 0.25) 10px,
    rgba(12, 66, 168, 0.25) 10px,
    rgba(12, 66, 168, 0.25) 20px
  );
  background-size: 200%;
  background-position: right;
  background-attachment: fixed;
  animation: animateBg 120s linear infinite alternate;
}

@keyframes animateBg {
  100% {
    background-position: left;
  }
}

.turnarounds .turnaround-timeline-bar.gtat:hover {
  background-color: rgba(23, 121, 218, 0.5);
  border-left-color: rgba(208, 47, 68, 0.75);
}

.turnarounds .turnaround-timeline-bar-container .turnaround-timeline-bar.gate {
  color: var(--light-font-color);
}

.turnarounds .turnaround-timeline-bar-title {
  grid-template-columns: 1fr auto;
}

.turnarounds .turnaround-timeline-bar-title,
.turnarounds .turnaround-operation-bar {
  display: grid;
  align-items: center;
  border-radius: 6px;
}

.turnarounds .turnaround-timeline-bar-title > div {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 4px;
}
.turnarounds .turnaround-timeline-bar-title .delay-indicator {
  margin-top: 4px;
}

.turnarounds
  .turnaround-timeline-bar-container.selected
  .turnaround-timeline-bar.gtat
  .turnaround-timeline-main {
  display: grid;
  gap: 8px;
}

.turnarounds
  .turnaround-timeline-bar-container.selected
  .turnaround-operation-bar {
  background-color: rgba(193, 199, 208, 0.25);
}
.turnarounds
  .turnaround-timeline-bar-container.selected
  .turnaround-operation-bar.selected,
.turnarounds
  .turnaround-timeline-bar-container.selected
  .turnaround-operation-bar:hover {
  background-color: rgba(193, 199, 208, 0.5);
}

.turnarounds .turnaround-operation-bar:last-child {
  margin-bottom: 8px;
}

.turnarounds
  .turnaround-timeline-bar-container
  .turnaround-timeline-bar.gate:hover {
  background-color: rgba(193, 199, 208, 0.5);
}

.turnarounds .turnarounds-timeline-toolbar {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.turnarounds .turnarounds-timeline-toolbar h2 {
  font-size: 24px;
  font-weight: 600;
}

.turnarounds .turnarounds-timeline-toolbar .centered {
  text-align: center;
  cursor: default;
}

.turnarounds .turnarounds-timeline-toolbar .actions {
  text-align: right;
  margin: 8px 24px;
}

.turnarounds .date-navigation {
  display: inline-grid;
  grid-template-columns: repeat(3, minmax(32px, auto));
  align-items: center;
  gap: 16px;
  margin-left: 16px;
}
.turnarounds .date-navigation > div:first-child {
  display: grid;
  justify-items: right;
}

.turnarounds .date-title {
  text-wrap: nowrap;
}
.turnarounds .date-title h3 {
  text-overflow: ellipsis;
  overflow: hidden;
}

.turnarounds .date-navigation button {
  width: 30px;
  display: grid;
  padding: 0;
  justify-items: center;
  height: 32px;
  align-items: center;
}
.turnarounds .date-navigation button svg {
  margin: 0;
}

.turnarounds .timeline-marker {
  width: 1px;
  height: 100%;
  border-left: 1px solid var(--highlight-bg-color);
  position: absolute;
  left: 50%;
  opacity: 0.25;
  cursor: default;
  overflow: visible;
}
.turnarounds .timeline-marker:hover {
  opacity: 0.5;
}
.turnarounds .timeline-marker .timeline-marker-badge {
  display: inline-grid;
  background-color: var(--highlight-bg-color);
  color: var(--pure-black);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  border-radius: 5px;
  padding: 4px 8px;
  margin: 4px 8px;
  cursor: default;
}

.context-panel {
  overflow: hidden;
  background-color: var(--dark-bg-color);
  border: 1px solid var(--outline-darker-color);
  border-radius: 8px;
  display: grid;
  align-content: start;
  gap: 8px;
}
.context-panel .context-panel-container {
  overflow-y: auto;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}
.context-panel .context-panel-content-header {
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  align-items: center;
}
.context-panel .context-panel-content-header .back-btn {
  margin-left: 16px;
}
.gate-change-panel .close-btn,
.turnaround-alerts-panel .close-btn,
.context-panel .context-panel-content-header .close-btn {
  margin: 16px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  opacity: 0.7;
}
.gate-change-panel .close-btn:hover,
.turnaround-alerts-panel .close-btn:hover,
.context-panel .context-panel-content-header .close-btn:hover {
  opacity: 1;
}

.context-panel .context-panel-section-header {
  font-size: 12px;
  text-transform: uppercase;
}
.context-panel .context-panel-titlebar {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
}
.context-panel .context-panel-titlebar h4 {
  margin-left: 16px;
}
.context-panel .context-panel-titlebar-details {
  display: grid;
  align-items: center;
  margin: 8px 0;
}
.context-panel .context-panel-titlebar-details > div {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.turnaround-content {
  display: grid;
  grid-template-rows: repeat(5, auto) 1fr;
  gap: 24px;
  margin: 16px;
  opacity: 0;
  cursor: default;
}
.turnaround-content.open {
  transition-property: opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  opacity: 1;
}
.turnaround-content .turnaround-flights {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr auto;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
}
.turnaround-content .turnaround-flights span {
  font-size: 8px;
  font-weight: 500;
  text-transform: uppercase;
}

.turnaround-content .turnaround-flights .inbound,
.turnaround-content .turnaround-flights .outbound {
  display: grid;
}
.turnaround-content .turnaround-flights .outbound {
  text-align: right;
}

.turnaround-content .turnaround-general {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
.turnaround-content .turnaround-general .turn-thirds {
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 8px;
}
.turnaround-content .turnaround-general .turn-locations {
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 8px;
}
.turnaround-content .turnaround-general .stat {
  display: grid;
  border: 1px solid var(--outline-color);
  border-radius: 8px;
  gap: 2px;
  cursor: default;
  background-color: var(--translucent);
  grid-template-rows: auto 1fr;
}

.turnaround-content .turnaround-general .stat.double {
  grid-column: span 2;
}

.turnaround-content .turnaround-general span {
  font-size: 14px;
  font-weight: 600;
  margin: 0 8px 8px 8px;
}
.turnaround-content .turnaround-general span.striked {
  text-decoration: line-through var(--medium-font-color) 1px solid;
  margin: 0 4px 0 0;
  color: var(--medium-font-color);
  font-size: 10px;
  font-weight: 500;
}
.turnaround-content .turnaround-general span.turnaround-remarks > div {
  margin: 0.5em 0;
  font-size: 12px;
  font-weight: 500;
}
.turnaround-content .turnaround-general label {
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 8px 8px 0 8px;
}

.turnaround-content h4 {
  margin-top: 16px;
}

.turnaround-content .turnaround-operations {
  display: grid;
  gap: 8px;
}

.turnaround-content .turnaround-operations .stat {
  display: grid;
  border: 1px solid var(--outline-color);
  border-radius: 8px;
  background-color: var(--translucent);
  align-items: center;
}
.turnaround-content .turnaround-operations .stat.unassigned {
  border: 1px solid var(--outline-color);
  border-radius: 8px;
  background-color: var(--translucent);
  height: 48px;
}

.turnaround-content .turnaround-operations .stat:hover {
  transition: border 0.25s ease;
  border: 1px solid var(--outline-light-color);
  cursor: pointer;
}
.turnaround-content .turnaround-operations .stat.selected {
  border: 1px solid var(--outline-light-color);
  background-color: var(--light-bg-color);
  cursor: pointer;
}

.turnaround-content .turnaround-operations .stat-content {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin: 8px;
}
.turnaround-content .turnaround-operations label {
  font-size: 12px;
  font-weight: 600;
  color: var(--base-font-color);
  grid-column: 1;
  margin-bottom: 2px;
}
.turnaround-content .turnaround-operations .status-info {
  display: grid;
  gap: 4px;
}
.turnaround-content .turnaround-operations .status-info span.allcap {
  text-transform: uppercase;
}

.context-panel .context-panel-titlebar h3 .status-info-pulse {
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background-color: var(--base-font-color);
  display: inline-block;
  margin: 0 0 6px 8px;
  vertical-align: middle;
}
.turnaround-content .turnaround-operations .status-info .status-info-pulse {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: var(--base-font-color);
  display: inline-block;
  margin: 0 0 1px 4px;
}
.turnaround-content .status-message {
  font-size: 12px;
  font-weight: 500;
  border: 1px solid var(--outline-color);
  background-color: var(--intermediate-bg-color);
  border-radius: 8px;
}
.turnaround-content .status-message > div {
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 8px;
  gap: 8px;
  align-items: center;
}
.turnaround-content .status-message svg {
  width: 24px;
  height: 24px;
}
.turnaround-content .status-message.status-100,
.turnaround-content .status-message.status-200,
.turnaround-content .status-message.status-302 {
  color: var(--base-font-color);
}
.turnaround-content .status-message.status-201,
.turnaround-content .status-message.status-301 {
  color: var(--primary-yellow);
}
.turnaround-content .status-message.status-300 {
  color: var(--primary-green);
}
.turnaround-content .status-message.status-400 {
  color: var(--primary-red);
}

.turnaround-content .turnaround-operations .status {
  display: grid;
  gap: 4px;
  align-items: center;
  justify-items: end;
}

.turnaround-content .turnaround-vehicle .status-ring {
  display: grid;
  margin: 12px;
  border: 1px solid var(--pure-white);
  border-radius: 1rem;
  width: 18px;
  height: 18px;
}

.turnaround-content .turnaround-operations .status-indicator,
.turnaround-content .turnaround-vehicle .status-indicator {
  border-radius: 1rem;
  background-color: var(--primary-green);
  width: 12px;
  height: 12px;
}
.turnaround-content .turnaround-vehicle.empty .status-indicator {
  border-radius: 1rem;
  background-color: var(--primary-off-white);
}
.turnaround-content .turnaround-vehicles .status-indicator {
  border: 3px solid var(--outline-medium-color);
}
.turnaround-content .turnaround-operations .status-indicator.status-1,
.turnaround-content .turnaround-vehicle .status-indicator.status-1 {
  border-radius: 1rem;
  background-color: var(--primary-off-white);
}
.turnaround-content .turnaround-operations .status-indicator.status-2,
.turnaround-content .turnaround-vehicle .status-indicator.status-2 {
  border-radius: 1rem;
  background-color: var(--primary-red);
}
.turnaround-content .turnaround-operations .status-indicator.status-3,
.turnaround-content .turnaround-vehicle .status-indicator.status-3 {
  border-radius: 1rem;
  background-color: var(--primary-yellow);
}
.turnaround-content .turnaround-operations .status-indicator.status-4,
.turnaround-content .turnaround-vehicle .status-indicator.status-4 {
  border-radius: 1rem;
  background-color: var(--primary-green);
}

.turnaround-content .crew-info span.time-completed,
.turnaround-content .turnaround-vehicle-info span.time-completed {
  color: var(--base-font-color);
}
.turnaround-content .status-text.status-complete {
  color: var(--primary-green);
}
.turnaround-content .crew-info span.time-initiated,
.turnaround-content .turnaround-vehicle-info span.time-initiated {
  color: var(--base-font-color);
}
.turnaround-content .status-text.status-inprogress {
  color: var(--primary-yellow);
}
.turnaround-content .status-text.status-delayed {
  color: var(--ary-red);
}
.turnaround-content .status-text.status-none {
  color: var(--placeholder);
}
.turnaround-content .status-text.status-empty {
  color: var(--primary-yellow);
}

.turnaround-content .turnaround-operations span {
  font-size: 9px;
  font-weight: 500;
  color: var(--light-font-color);
  grid-column: 1 / span 2;
}

.turnaround-content .turnaround-flight-header {
  border: 1px solid var(--outline-color);
  border-radius: 8px;
  background-color: var(--translucent);
}
.turnaround-content .turnaround-flight-header > div {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  margin: 10px 8px;
  align-items: center;
}
.turnaround-content .turnaround-flight-header svg {
  vertical-align: middle;
}
.turnaround-content .turnaround-flight-header .title {
  display: grid;
  grid-template-rows: auto 1fr;
}
.turnaround-content .turnaround-flight-header .title label {
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
}
.turnaround-content .turnaround-flight-header .title span {
  font-size: 12px;
  font-weight: 600;
  color: var(--base-font-color);
}

.turnaround-content .turnaround-flight-status span {
  font-size: 14px;
  font-weight: 600;
}

.turnaround-content hr {
  border: 1px solid var(--outline-color);
}

.turnaround-content .turnaround-vehicles,
.turnaround-content .turnaround-crew-list {
  display: grid;
  gap: 16px;
}
.turnaround-content .turnaround-crew-list .turnaround-crew-list-actions button {
  width: 100%;
}
.turnaround-content .turnaround-vehicle {
  display: grid;
  align-items: center;
  border: 1px solid var(--outline-color);
  border-radius: 8px;
  background-color: var(--medium-bg-color);
  transition: background-color 0.25s ease;
}
.turnaround-content .turnaround-vehicle > div {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin: 2px 0;
}
.turnaround-content .turnaround-vehicle.empty > div {
  grid-template-columns: 1fr auto;
  margin: 12px;
}
.turnaround-content .turnaround-vehicle .turnaround-vehicle-allocated label {
  font-weight: 600;
}
.turnaround-content .turnaround-vehicle .turnaround-vehicle-allocated > div {
  display: grid;
  gap: 4px;
}

.turnaround-content .turnaround-crew:not(.selected).selectable:hover,
.turnaround-content .turnaround-vehicle:not(.selected).selectable:hover {
  border: 1px solid var(--outline-light-color);
  cursor: pointer;
  transition: border 0.25s ease;
}
.turnaround-content .turnaround-crew.selected,
.turnaround-content .turnaround-vehicle.selected {
  border: 1px solid var(--outline-light-color);
  background: linear-gradient(0turn, #212c40, #161c27);
}
.turnaround-content .turnaround-vehicle.empty {
  border: 1px dashed var(--primary-red);
  background-color: transparent;
}
.turnaround-content .turnaround-vehicle.empty.selected {
  border: 1px solid var(--outline-light-color);
  background: linear-gradient(0turn, #212c40, #161c27);
}
.turnaround-content .turnaround-vehicle.empty.selectable:not(.selected):hover {
  border: 1px dashed var(--outline-light-color);
}
.turnaround-content .turnaround-vehicle-info > div {
  margin: 8px 8px 8px 0;
  display: grid;
  gap: 4px;
}
.turnaround-content .turnaround-vehicle-info label {
  font-size: 14px;
  font-weight: 600;
  color: var(--base-font-color);
}
.turnaround-content .turnaround-vehicle-info span {
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--light-font-color);
}

.turnaround-content .turnaround-vehicle-info .gse-name {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
}
.turnaround-content .turnaround-vehicle-info .gse-name > div {
  position: relative;
}
.turnaround-content .turnaround-vehicle-info .battery-power {
  transform: scale(0.6);
  position: absolute;
  top: -14px;
}
.turnaround-content .turnaround-vehicle-info .battery-power span {
  font-size: 14px;
  font-weight: 500;
}
.turnaround-content .turnaround-vehicle-info .battery-power-box > div {
  display: grid;
}

.turnaround-content .turnaround-vehicle-info .gse-type-badge {
  display: grid;
  align-items: center;
  background-color: var(--primary-off-white);
  display: inline-block;
  border-radius: 4px;
  margin: 4px 0;
}
.turnaround-content .turnaround-vehicle-info .gse-type-badge > div {
  margin: 2px 6px;
  color: var(--darker-font-color);
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
}
.turnaround-content .turnaround-vehicle-info span.status {
  margin-bottom: 4px;
}

.turnaround-content .turnaround-crew {
  display: grid;
  align-items: center;
  border-radius: 8px;
  background-color: var(--translucent);
  border: 1px solid var(--outline-color);
  transition: background-color 0.25s ease;
}

.turnaround-content .turnaround-crew .profile-pic {
  margin: 8px;
}
.turnaround-content .crew-info {
  margin: 12px;
}
.turnaround-content .crew-info > div {
  display: grid;
  gap: 4px;
  align-items: center;
}
.turnaround-content .crew-info .crew-info-actions {
  display: grid;
  gap: 8px;
  align-items: center;
}

.turnaround-content .crew-info label {
  font-size: 14px;
  font-weight: 600;
  color: var(--base-font-color);
}

.turnaround-content .crew-info span {
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--light-font-color);
}
.turnaround-content .crew-info .crew-info-detail {
  display: grid;
  gap: 4px;
}
.turnaround-content .crew-info .certification-name {
  display: grid;
  align-items: center;
  background-color: var(--primary-off-white);
  display: inline-block;
  border-radius: 4px;
  margin: 4px 0;
}
.turnaround-content .crew-info .certification-name > div {
  margin: 2px 6px;
  color: var(--darker-font-color);
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
}
.turnaround-content .crew-info span.status {
  margin-bottom: 4px;
}
.turnaround-content .crew-info span.accepted {
  color: var(--primary-blue);
}
.turnaround-content .crew-info span.in-progress,
.turnaround-content .crew-info span.pending {
  color: var(--primary-yellow);
}
.turnaround-content .crew-info span.completed {
  color: var(--primary-green);
}

.turnaround-content button {
  padding: 8px 10px;
  font-size: 12px;
  font-weight: 500;
}

.turnaround-content .turnaround-action {
  display: grid;
  gap: 12px;
}
.turnaround-content .turnaround-action button {
  width: 100%;
}

.turnaround-content .turnaround-action span {
  display: grid;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--medium-font-color);
}

/* Turnaround Progress Bar */
.turnaround-progress {
  display: grid;
  gap: 12px;
}
.turnaround-progress .progress-info {
  display: grid;
  grid-template-columns: auto minmax(32px, 1fr) auto;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
  gap: 8px;
}
.turnaround-progress .progress-info svg {
  vertical-align: middle;
}
.turnaround-progress .progress-info .status-badge {
  font-weight: 600;
  font-size: 10px;
  padding: 4px;
  border-radius: 4px;
  color: var(--darker-font-color);
  background-color: var(--primary-off-white);
  text-wrap: nowrap;
}

.turnaround-progress.in-service .progress-info .status-badge {
  background-color: var(--primary-off-white);
}
.turnaround-progress.completed .progress-info .status-badge {
  background-color: var(--primary-green);
}

.turnaround-progress .progress-info .operation-status {
  display: grid;
  align-items: center;
  text-wrap: nowrap;
}
.turnaround-progress .progress-info .operation-status.double {
  grid-template-columns: auto 1fr;
  gap: 4px;
}

.turnaround-progress .progress-info .operation-status-badge {
  display: grid;
  align-items: center;
  border-radius: 6px;
}
.turnaround-progress .progress-info .operation-status-badge svg {
  vertical-align: middle;
  margin-top: -2px;
}
.turnaround-progress .progress-info .operation-status-badge > div {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 2px;
  text-wrap: nowrap;
  margin: 4px 6px;
  font-size: 10px;
  font-weight: 600;
}
.turnaround-progress .progress-info .operation-status-badge.status-critical,
.turnaround-progress .progress-info .operation-status-badge.status-400 {
  color: var(--primary-red);
  background-color: var(--primary-red-semi);
}
.turnaround-progress .progress-info .operation-status-badge.status-201 {
  color: var(--primary-yellow);
  background-color: var(--primary-yellow-semi);
}

.turnaround-progress .progress-bar {
  width: 100%;
  background-color: var(--placeholder);
  height: 7px;
  border-radius: 1rem;
  position: relative;
}
.turnaround-progress .progress-bar-filled {
  width: 50%;
  height: 100%;
  background-color: var(--primary-off-white);
  position: absolute;
  border-radius: 1rem;
}

.turnaround-progress.in-progress .progress-bar-filled {
  background-color: var(--primary-off-white);
}
.turnaround-progress.completed .progress-bar-filled {
  background-color: var(--primary-green);
}

.turnaround-operation-status-info {
  margin-top: 12px;
  border-radius: 8px;
}
.turnaround-operation-status-info > div {
  display: grid;
  gap: 4px;
  margin: 8px;
}
.turnaround-operation-status-info .turnaround-operation-status-item {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
}
.turnaround-operation-status-info .turnaround-operation-status-item svg {
  vertical-align: middle;
}
.turnaround-operation-status-info .turnaround-operation-status-item.status-400 {
  color: var(--primary-red);
}
.turnaround-operation-status-info .turnaround-operation-status-item.status-201 {
  color: var(--primary-yellow);
}
.turnaround-operation-status-info.status-400 {
  border: 1px solid var(--primary-red-semi);
  background-color: var(--primary-red-tint);
}
.turnaround-operation-status-info.status-201 {
  border: 1px solid var(--primary-yellow-semi);
  background-color: var(--primary-yellow-tint);
}
.turnaround-operation-status-info > div {
  display: grid;
  gap: 4px;
  margin: 8px;
}

.alert-timeline {
  display: grid;
  gap: 32px;
  margin: 16px 0;
  position: relative;
}
.alert-timeline .vertical-line {
  position: absolute;
  height: calc(100% - 16px);
  border-left: 1px solid var(--light-font-color);
  top: 8px;
  left: 11px;
  z-index: -1;
}
.alert-timeline svg {
  vertical-align: middle;
}
.alert-timeline span {
  font-size: 11px;
  color: var(--input-font-color);
}

.alert-timeline .alert-timeline-item {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 16px;
  align-items: center;
  font-size: 12px;
  color: var(--base-font-color);
}

.turnaround-alerts-panel h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--base-font-color);
}

/** Turnaround crew slot **/
.turnaround-content .turnaround-crew-slot {
  display: grid;
  border: 1px dashed var(--primary-red);
  align-items: center;
  border-radius: 8px;
}
.turnaround-content .turnaround-crew-slot.selected {
  border: 1px solid var(--outline-light-color);
  background: linear-gradient(0turn, #212c40, #161c27);
}
.turnaround-content .turnaround-crew-slot.hover {
  border: 1px dashed var(--outline-light-color);
}
.turnaround-content .turnaround-crew-slot > div {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 8px;
  margin: 12px;
}
.turnaround-content .turnaround-crew-slot label {
  font-weight: 600;
}
.turnaround-content .turnaround-crew-slot .turnaround-crew-slot-info {
  display: grid;
  gap: 4px;
}
.turnaround-content .turnaround-crew-slot.selectable:not(.selected):hover {
  border: 1px dashed var(--outline-light-color);
  cursor: pointer;
}

/** Locator panel **/
.locator-panel-container {
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
}
.locator-panel-container > div {
  display: grid;
  margin: 16px;
  gap: 12px;
}

.unassigned-user-panel-summary > div,
.locator-panel-container .locator-panel-summary > div {
  font-size: 14px;
  margin: 8px 0;
}

.locator-panel-container .locator-panel-items {
  display: grid;
  gap: 8px;
}

.locator-panel-container .locator-panel-items .overlay-module {
  min-width: auto;
  border: 1px solid var(--outline-color);
}
.locator-panel-container .locator-panel-items .overlay-module.selected {
  border: 1px solid var(--outline-light-color);
}

/** Unassigned panel **/
.unassigned-user-panel .turnaround-content {
  grid-template-rows: 1fr;
}

/** Critical state section **/
.turnaround-content .context-panel-critical-section {
  display: grid;
}
.context-panel .context-panel-critical-section > div {
  display: grid;
  gap: 8px;
  margin: 0 16px;
}
.context-panel .context-panel-critical-section .actions {
  display: grid;
}
.context-panel .context-panel-critical-section .actions button {
  padding: 6px 8px;
  font-size: 12px;
}
.context-panel .context-panel-critical-section .note {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
}
