.time-range-input {
  display: inline-flex;
  background-color: var(--darker-bg-color);
  border-radius: 8px;
}
.time-range-input > div {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 2px;
  margin: 6px;
}
.time-input {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 2px;
  font-weight: 500;
  background-color: var(--input-bg-color);
  border-radius: 6px;
}
.time-input .time-input-colon {
  margin-top: -3px;
  font-size: 14px;
}
.time-input select {
  width: 2em;
  text-align: center;
  font-weight: 500;
  border: none;
}
.time-input select.hour-input {
  padding: 4px 0 4px 4px;
}
.time-input select.minute-input {
  padding: 4px 4px 4px 0;
}
