.select-crew-slot {
  display: grid;
  border: 1px dashed var(--outline-color);
  border-radius: 12px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.25s ease-out;
}
.select-crew-slot.disabled {
  cursor: not-allowed;
}

.select-crew-slot .crew-info {
  cursor: inherit;
}

.select-crew-slot.assigned {
  border: 1px solid var(--outline-color);
}
.select-crew-slot.selected {
  border: 1px solid var(--primary-blue-dim);
}
.select-crew-slot .unassigned {
  color: var(--medium-font-color);
}

.select-crew-slot:not(.disabled):hover {
  border: 1px solid var(--outline-light-color);
}
.select-crew-slot:not(.disabled).selected:hover {
  border: 1px solid var(--primary-blue);
}

.select-crew-slot > div {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin: 8px;
}

.select-crew-slot .timestamps {
  font-size: 10px;
  font-weight: 500;
  color: var(--medium-font-color);
  text-transform: uppercase;
}

.select-crew-slot-content {
  display: grid;
  grid-template-columns: 1fr auto;
}
