.tracker-status {
  display: grid;
  gap: 6px;
}
.tracker-status .cell {
  display: grid;
  background-color: var(--intermediate-bg-color);
  border-radius: 6px;
}
.tracker-status .cell > div {
  display: grid;
  align-items: center;
  grid-template-columns: 20px 1fr;
  margin: 0 6px;
  height: 20px;
  gap: 4px;
}
.tracker-status .cell-icon-battery {
  display: grid;
  align-items: center;
  position: relative;
}
.tracker-status .cell-icon-battery-fill {
  width: 16px;
  height: 8px;
  border-radius: 2px;
  background-color: var(--light-font-color);
  margin-top: -1px;
}
.tracker-status .cell-icon-battery svg {
  position: absolute;
  z-index: 1;
}
.tracker-status .cell-icon-cell,
.tracker-status .cell-icon-sat {
  display: grid;
  align-items: center;
}
.tracker-status .cell-icon-cell svg,
.tracker-status .cell-icon-sat svg {
  width: 16px;
  height: 16px;
}
.tracker-status .cell-value {
  font-size: 9px;
  font-weight: 600;
  color: var(--light-font-color);
}
