.performance {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  overflow: hidden;
}
.performance .page-toolbar {
  align-items: center;
}
.performance .page-toolbar .daterange-picker {
  margin-right: 32px;
}
.performance .performance-toolbar {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 32px;
  align-items: center;
}
.performance .performance-toolbar h2 {
  font-size: 24px;
  text-wrap: nowrap;
}
.performance .performance-container {
  display: grid;
  grid-template-rows: auto 1fr;
  margin: 0 0 0 16px;
  gap: 16px;
  overflow: hidden;
}
.performance .charts-container {
  display: grid;
  height: 100%;
  overflow-y: hidden;
}
.performance .charts-container.side-panel {
  grid-template-columns: 220px 1fr;
  gap: 16px;
}
.performance .charts-content {
  display: grid;
  height: 100%;
  grid-template-rows: auto auto auto 1fr;
  gap: 16px;
  overflow-y: auto;
}

.performance .chart-module {
  border-radius: 12px;
  background-color: var(--dark-bg-color);
  margin-right: 16px;
}
.performance .chart-module.transparent {
  background-color: transparent;
}

.performance .chart-module > div {
  margin: 24px;
}

.performance .chart-title-bar {
  display: grid;
  gap: 4px;
}

.performance .chart-title-bar h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}

.performance .chart-title-metrics {
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(3, auto) 1fr;
}

.performance .chart-title-metrics > div {
  display: grid;
  gap: 8px;
}
.performance .chart-title-metrics > div.primary,
.performance .chart-title-metrics > div.primary label {
  color: var(--primary-blue);
}
.performance .chart-title-metrics label {
  font-size: 14px;
  font-weight: 500;
}

.performance .chart-title-metrics h3 {
  font-size: 24px;
  font-weight: 600;
}

.performance .chart-tiles {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}
.performance .chart-tiles.two {
  grid-template-columns: repeat(2, 1fr);
}
.performance .chart-tiles.three {
  grid-template-columns: repeat(3, 1fr);
}

.performance .chart-tile {
  background-color: var(--dark-bg-color);
  border-radius: 12px;
  cursor: default;
}
.performance .chart-tile > div {
  display: grid;
  gap: 8px;
  margin: 24px;
}
.performance .chart-tile.mainchart {
  min-height: 540px;
}
.performance .chart-tile-header h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.performance .chart-tile-header h3 {
  font-size: 16px;
  font-weight: 600;
}

.performance .chart-tile-container {
  display: grid;
}
.performance .chart-tile-container.wide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  align-items: center;
}
.performance .chart-tile-container.wide .chart-tile-body .center-detail span {
  font-size: 10px;
}
.performance .chart-tile-container.wide .chart-tile-body .center-info {
  font-size: 12px;
}
.performance .chart-tile-container .chart-tile-body .center-info .metric-value {
  font-size: 14px;
}
.performance .chart-tile-container .chart-tile-body.basic {
  display: grid;
  gap: 8px;
  margin: 16px 0 32px 0;
}
.performance .chart-tile-container .chart-tile-body.basic .complete-labels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  text-wrap: nowrap;
}
.performance
  .chart-tile-container
  .chart-tile-body.basic
  .complete-labels
  > div:last-child {
  text-align: right;
}
.performance .chart-tile-container .chart-tile-body.basic .complete-fill {
  display: grid;
  background-color: var(--metrics-1);
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
}
.performance
  .chart-tile-container
  .chart-tile-body.basic
  .complete-fill-amount {
  background-color: var(--metrics-3);
  border-radius: 4px;
}

.performance .chart-tile-container.wide .chart-tile-footer {
  font-size: 12px;
  grid-template-rows: 1fr 1fr;
  align-items: center;
}
.performance .chart-tile-container.wide .chart-tile-footer .chart-tile-legend {
  margin: 0;
}
.performance
  .chart-tile-container.wide
  .chart-tile-footer
  .chart-tile-legend-footnote {
  text-align: left;
  margin: 0;
}

.performance .chart-tile-container .chart-tile-body {
  display: grid;
  grid-template-rows: auto 1fr;
  min-width: 160px;
}

.performance .chart-tile-container .chart-tile-body {
  display: grid;
  position: relative;
}

.performance .chart-tile-container .chart-tile-body .center-info {
  position: absolute;
  z-index: 2;
  text-align: center;
  top: 50%;
  transform: translateX(-50%) translateY(-60%);
  left: 50%;
  font-size: 14px;
  font-weight: 500;
  display: grid;
  gap: 4px;
}
.performance .chart-tile-container .chart-tile-body .center-info .metric-value {
  font-size: 16px;
  font-weight: 600;
}
.performance .chart-tile-container .chart-tile-body .center-detail {
  border: 1px solid var(--outline-light-color);
  border-radius: 4px;
  display: grid;
  align-items: center;
}
.performance .chart-tile-container .chart-tile-body .center-detail span {
  font-weight: 500;
  font-size: 11px;
  color: var(--font-color-light);
  margin: 4px;
}

.performance .metric-bar-header {
  display: grid;
  grid-template-columns: 1fr auto;
}
.performance .metric-bar-header label {
  font-size: 14px;
  font-weight: 600;
}
.performance .metric-bar-header span {
  font-size: 14px;
  font-weight: 700;
  color: var(--base-font-color);
}

.performance .metric-bar-container {
  display: grid;
  position: relative;
  height: 8px;
  border-radius: 1rem;
  background-color: var(--light-bg-color);
  overflow: hidden;
}
.performance .chart-tile .metric-bar-fill {
  background-color: var(--primary-green);
  height: 8px;
  width: 0%;
}
.performance .chart-tile.danger .metric-bar-fill {
  background-color: var(--primary-red);
}

.performance .date-navigation {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 16px;
  margin-right: 32px;
}
.performance .chart-tile-footer {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
}
.performance .chart-tile-footer label,
.performance .chart-tile-footer {
  font-size: 12px;
  font-weight: 500;
}

.performance .chart-tile-container .chart-tile-footer {
  display: grid;
  grid-template-columns: 1fr;
}

.performance .chart-tile-container .chart-tile-footer .chart-tile-legend {
  display: grid;
  gap: 8px;
  margin: 8px;
  color: var(--light-font-color);
}
.performance .chart-tile-container .chart-tile-footer .chart-tile-legend > div {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
  align-items: center;
  font-size: 14px;
}
.performance
  .chart-tile-container
  .chart-tile-footer
  .chart-tile-legend
  .colored-dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
.performance
  .chart-tile-container
  .chart-tile-footer
  .chart-tile-legend-footnote {
  text-align: center;
  font-size: 14px;
  color: var(--light-font-color);
}

.performance .metrics-filters {
  display: grid;
  margin: 0 16px;
  align-items: center;
  gap: 8px;
}

.performance .charts-side-panel-content {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: hidden;
}
.performance .charts-side-panel {
  display: grid;
  grid-template-rows: auto 1fr;
  cursor: default;
  margin-bottom: 16px;
  overflow: hidden;
}
.performance .charts-side-panel .charts-side-panel-container {
  display: grid;
  overflow-y: auto;
  border-radius: 12px;
  background-color: var(--dark-bg-color);
}
.performance .charts-side-panel .charts-side-panel-container > div {
  display: grid;
  gap: 16px;
  margin: 16px;
}
.performance .charts-side-panel-item {
  display: grid;
  grid-template-columns: 1fr auto;
  border-radius: 12px;
  border: 1px solid var(--outline-medium-color);
  font-size: 14px;
  font-weight: 600;
  color: var(--light-font-color);
  align-items: center;
  transition: background-color 0.25s ease;
}
.performance .charts-side-panel-item:hover,
.performance .charts-side-panel-item.selected {
  color: var(--base-font-color);
  cursor: pointer;
  background-color: var(--intermediate-bg-color);
}

.performance .charts-side-panel-item.selected {
  background-color: var(--medium-bg-color);
}
.performance .charts-side-panel-item > div {
  margin: 12px;
}

.performance .chart-component {
  background-color: var(--darker-bg-color);
  border-radius: 12px;
}

.performance .chart-sort-bar {
  display: grid;
  grid-template-columns: repeat(5, auto) 1fr;
  gap: 16px;
  align-items: center;
  border-bottom: 1px solid var(--outline-medium-color);
  margin-bottom: 24px;
  cursor: default;
}
.performance .chart-sort-bar .chart-sort-label,
.performance .chart-sort-bar .chart-sort-item {
  font-size: 14px;
  font-weight: 600;
  color: var(--light-font-color);
  border-bottom: 2px solid transparent;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}
.performance .chart-sort-bar .chart-sort-item:hover,
.performance .chart-sort-bar .chart-sort-item.selected {
  border-bottom: 2px solid var(--base-font-color);
  color: var(--base-font-color);
  cursor: pointer;
}
.performance .chart-sort-bar .chart-sort-label > div,
.performance .chart-sort-bar .chart-sort-item > div {
  margin: 8px 0 12px 0;
}

.performance .daterange-picker button {
  padding: 10px;
}
.performance .checkbox-filter {
  text-wrap: nowrap;
}
